<template>
  <div id="app">
    <div class="wrapper-with-side-menu" v-if="isShowSideMenu">
      <side-menu />
      <div class="container-with-side-menu">
        <Header v-if="isShowHeader" @mobileMenuToggle="mobileMenuToggle" />
        <router-view :class="{'mobile-menu-open': isMobileMenuOpen}" />
      </div>
    </div>
    <div class="wrapper-without-side-menu" v-if="!isShowSideMenu">
      <Header v-if="isShowHeader" />
      <router-view />
    </div>
    <Footer v-if="isShowFooter" />
    <base-spinner
        :is-loading="isLoading"
    />
    <base-error-modal
        v-if="errorMessage"
        :error-message="errorMessage"
    />
    <base-modal-portal />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseSpinner from './components/base/BaseSpinner.vue'
import BaseErrorModal from './components/base/BaseErrorModal.vue'
import Header from './components/common/Header.vue'
import Footer from './components/common/Footer.vue'
import SideMenu from './components/common/SideMenu.vue'
import { settings } from '@/services/Settings'
import BaseModalPortal from '@/components/base/BaseModalPortal.vue'

export default {
  name: 'App',
  components: {
    BaseModalPortal,
    BaseSpinner,
    BaseErrorModal,
    Header,
    Footer,
    SideMenu
  },
  data() {
    return {
      isMobileMenuOpen: false
    }
  },
  computed: {
    ...mapGetters(['isLoading', 'errorMessage']),
    isShowHeader() {
      return this.$route.name !== 'SignIn' && this.$route.name !== 'NotFound' &&
          this.$route.name !== 'PasswordRecovery' && this.$route.name !== 'msi' &&
          this.$route.name !== 'SignUp' && this.$route.name !== 'SignUpV2' && this.$route.name !== 'main' && this.$route.name !== 'contract' && this.$route.name !== 'scoringAgreement' &&
          this.$route.name !== 'contacts' && this.$route.name !== 'legislation' && this.$route.name !== 'getLoan' &&
          this.$route.name !== 'investing' && this.$route.name !== 'questions' && this.$route.name !== 'about' &&
          this.$route.name !== 'arbitration' && this.$route.name !== 'documents' && this.$route.name !== 'news' &&
          this.$route.name !== 'MsiUpdateInfo' && this.$route.name !== 'confirmEmailResult'
    },
    isShowFooter() {
      return this.$route.name !== 'SignIn' && this.$route.name !== 'NotFound' &&
          this.$route.name !== 'PasswordRecovery' && this.$route.name !== 'msi' &&
          this.$route.name !== 'SignUp' && this.$route.name !== 'SignUpV2' && this.$route.name !== 'contract' && this.$route.name !== 'scoringAgreement'
    },
    isShowSideMenu() {
      return this.$route.name !== 'SignIn' && this.$route.name !== 'NotFound' &&
          this.$route.name !== 'PasswordRecovery' && this.$route.name !== 'msi' &&
          this.$route.name !== 'SignUp' && this.$route.name !== 'SignUpV2' && this.$route.name !== 'main' && this.$route.name !== 'contract' && this.$route.name !== 'scoringAgreement' &&
          this.$route.name !== 'contacts' && this.$route.name !== 'legislation' && this.$route.name !== 'getLoan' &&
          this.$route.name !== 'investing' && this.$route.name !== 'questions' && this.$route.name !== 'about' &&
          this.$route.name !== 'arbitration' && this.$route.name !== 'documents' && this.$route.name !== 'news' &&
          this.$route.name !== 'MsiUpdateInfo' && this.$route.name !== 'confirmEmailResult'
    }
  },
  async created() {
    this.setLocale()
    await this.$store.dispatch('checkAuth')
  },
  methods: {
    setLocale() {
      const locale = localStorage.getItem('locale')

      if (locale) {
        this.$i18n.locale = locale
      } else {
        const defaultLanguage = settings.defaultLanguage
        localStorage.setItem('locale', defaultLanguage)
        this.$i18n.locale = defaultLanguage
      }
    },
    mobileMenuToggle(value) {
      this.isMobileMenuOpen = value
    }
  }
}
</script>

<style lang="scss">
@import '../src/assets/style.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Bullet';
  src: url('./assets/fonts/Bullet/bullet.otf');
}

#app {
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  max-height: max-content;

  .required {
    &:after {
      content: '*';
      display: inline;
      color: var(--error-message-color)
    }
  }
}

* {
  font-family: 'Open Sans', sans-serif;
}

#app {
  .mx-input,
  .mx-datepicker,
  .mx-input-wrapper {
    height: 100%;
    width: 100%;
    border-radius: var(--base-input-border-radius);
  }

  .mx-input:hover:focus {
    border-color: var(--datepicker-hover-color);
  }

  .mx-input {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;

    &:not(:placeholder-shown) {
      background-color: #41BF7B1F;
      color: #1A8D48;
      border: none;
      outline: none;
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }

  .mx-input:hover {
    border-color: var(--datepicker-hover-color);
  }

  .mx-input:focus {
    border-color: var(--datepicker-hover-color);
  }

  input:disabled {
    background-color: var(--base-input-disabled-bgc);
    color: var(--base-input-disabled-color);
  }
}

body {
  .mx-calendar + .mx-calendar {
    display: none;
  }

  .mx-datepicker-popup {
    width: 348px;
    margin-left: 2px;
    box-sizing: border-box;
    box-shadow: 2px 2px 19px rgba(191, 191, 191, 0.45);
    border-radius: 20px;
  }

  .mx-calendar {
    width: 100%;
    padding: 36px 30px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    font-family: 'Open Sans', serif;
  }

  .mx-calendar-header {
    padding: 0 5px;
    margin-bottom: 15px;
    box-sizing: border-box;
  }

  .mx-btn:not(.mx-btn-current-month):not(.mx-btn-current-year) {
    color: var(--datepicker-btns-color);
  }

  .mx-btn-icon-right,
  .mx-btn-icon-left,
  .mx-btn-icon-double-right,
  .mx-btn-icon-double-left {
    transform: scale(1.5);
  }

  .mx-btn-text {
    padding: 0 8px;
    box-sizing: border-box;
  }

  .mx-btn-current-month,
  .mx-btn-current-year {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 17px;
    color: var(--datepicker-header-color);
  }

  .mx-table th {
    font-weight: 600;
    font-size: 16px;
    color: var(--datepicker-header-color);
  }

  .mx-table-date td {
    font-size: 16px;
    color: var(--datepicker-header-color);
  }

  .cell.today {
    color: var(--datepicker-btns-color);
    font-weight: 700;
  }

  .mx-calendar-content .cell.active {
    background-color: var(--datepicker-selected-date-bgc);
    color: var(--datepicker-selected-date-color);
  }
}

.wrapper-without-side-menu {
  min-height: 100%;
}

.wrapper-without-side-menu,
.wrapper-with-side-menu {
  width: 100%;
}

.wrapper-with-side-menu {
  display: flex;
  justify-content: flex-end;
  position: relative;
  min-height: 500px;
  // min-width: 1300px;
}

.container-with-side-menu {
  width: calc(100% - 327px);
  min-height: calc(100vh - 360px);
}

.mobile-menu-open {
  margin-top: 74px;
  visibility: hidden;
}

@media screen and (max-width: 1450px) {
  .container-with-side-menu {
    width: calc(100% - 270px);
  }
}

@media screen and (max-width: 1390px) {
  .container-with-side-menu {
    width: calc(100% - 240px);
  }
}

@media screen and (max-width: 1155px) {
  .container-with-side-menu {
    width: 100%;
  }
}
</style>
