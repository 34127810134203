<template>
  <div class="header" :class="{'header-menu-open': isMobileMenuOpen}">
      <header class="header-container">
        <div class="header-block">
          <div class="nav-block">
            <img @click="$router.push({ name: 'main' })" class="logo" src="../../../public/custom/images/logo.svg" alt="Logo image">
            <nav class="nav">
              <router-link :class="{'nav-item-checked': this.$route.name === 'getLoan'}" class="nav-item" to="get-loan">{{ $t('message.header.getLoan') }}</router-link>
              <router-link :class="{'nav-item-checked': this.$route.name === 'investing'}" class="nav-item" to="investing">{{ $t('message.header.investing') }}</router-link>
              <router-link :class="{'nav-item-checked': this.$route.name === 'about'}" class="nav-item" to="about">{{ $t('message.header.about') }}</router-link>
            </nav>
          </div>
          <div class="authorization-block">
            <div class="profile-img" v-if="isAuthorized" @click="$router.push({name: 'profile'})">{{ parsedUserName }}</div>
            <div class="reg-btn" @click="signUp()" v-if="!isAuthorized">{{ $t('message.header.register') }}</div>
            <div class="sign-out-block" v-if="isAuthorized">
              <base-button
                :text="$t('message.header.signOutBtn')"
                @click="signOut()"
                class="auth-button"
              />
            </div>
            <div class="sign-in-block" v-if="!isAuthorized">
              <base-button
                :text="$t('message.header.signInBtn')"
                @click="$router.push({name: 'SignIn'})"
                class="auth-button"
              />
            </div>
          </div>
          <div class="mobile-munu-nav-profile-block">
            <div class="profile-img" v-if="isAuthorized" @click="$router.push({name: 'profile'})">{{ parsedUserName }}</div>
          <img @click="mobileMenuToggle()" class="mobile-menu-open-img" :src="require(`../../../public/custom/images/main-page/mobile-menu-${isMobileMenuOpen ? 'close' : 'open'}.svg`)" alt="Mobile menu image">
          </div>
        </div>
        <div class="mobile-menu-container" :class="{'mobile-menu-container-open': isMobileMenuOpen}">
          <div class="mobile-nav-block">
            <router-link :class="{'nav-item-checked': this.$route.name === 'getLoan'}" class="nav-item" to="get-loan">{{ $t('message.header.getLoan') }}</router-link>
            <router-link :class="{'nav-item-checked': this.$route.name === 'investing'}" class="nav-item" to="investing">{{ $t('message.header.investing') }}</router-link>
            <router-link :class="{'nav-item-checked': this.$route.name === 'about'}" class="nav-item" to="about">{{ $t('message.header.about') }}</router-link>
          </div>
          <div class="mobile-authorization-block">
            <div class="sign-out-block" v-if="isAuthorized">
              <base-button
                :text="$t('message.header.signOutBtn')"
                @click="signOut()"
                class="auth-button"
              />
            </div>
            <div class="sign-in-block" v-if="!isAuthorized">
              <base-button
                :text="$t('message.header.signInBtn')"
                @click="$router.push({name: 'SignIn'})"
                class="auth-button"
              />
            </div>
            <!-- <div class="profile-img" v-if="isAuthorized" @click="$router.push({name: 'profile'})">{{ parsedUserName }}</div> -->
            <div class="reg-btn" @click="signUp()" v-if="!isAuthorized">{{ $t('message.header.register') }}</div>
          </div>
        </div>
        <div class="bottom-line"></div>
      </header>
    </div>
</template>

<script>
import BaseButton from '../base/BaseButton.vue'
import { mapGetters } from 'vuex'
import { StorageKey } from '@/models'

export default {
  name: 'StaticPagesHeader',
  components: {
    BaseButton
  },
  data() {
    return {
      isMobileMenuOpen: false
    }
  },
  methods: {
    signUp() {
      this.$router.push({ name: 'msi' })
    },
    async signOut() {
      await localStorage.removeItem(StorageKey.ACCESS)
      await this.$store.dispatch('purgeProfile')
    },
    mobileMenuToggle() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen

      this.$emit('mobileMenuToggle', this.isMobileMenuOpen)
    }
  },
  computed: {
    ...mapGetters(['profile']),
    isAuthorized() {
      return this.profile?.role
    },
    parsedUserName() {
      if (this.isAuthorized) {
        return this.profile.first_name?.slice(0, 1) + this.profile.last_name.slice(0, 1)
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/mixins';
  .header {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: white;
  }

  .header-menu-open {
    position: fixed;
    top: 0;
    left: 0;
  }

  .header-container {
    @include base-page-container;

    margin-bottom: 11px;
    height: 74px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .heading-container-mobile-menu-open {
    margin-top: 85px;
  }

  .mobile-menu-container {
    position: fixed;
    height: 100vh;
    width: calc(100vw);
    padding-top: 40px;
    padding-left: 10px;
    box-sizing: border-box;
    top: 74px;
    left: calc(100vw + 700px);
    display: flex;
    flex-direction: column;
    background-color: #F8F8F8;
    transition: left .2s ease-in-out;
    z-index: 1000;
  }

  .mobile-nav-block {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;

    .nav-item {
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 16px;
      line-height: 135%;
      color: #121212;
      width: fit-content;
      text-decoration: none;
    }
  }

  .mobile-authorization-block {
    display: flex;
    flex-direction: column;

    .sign-in-block,
    .sign-out-block {
      margin-left: 0;
      margin-bottom: 25px;
    }

    .reg-btn {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
    }
  }

  .mobile-menu-container-open {
    left: 0;
  }

  .bottom-line {
    @include base-page-container;

    max-width: 100%;
    height: 2px;
    border-radius: 3px;
    background-color: #ECEEF0;
  }

  .header-block {
    @include base-page-container;

    height: 100%;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .nav-block {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .logo {
    margin-right: 55px;
    cursor: pointer;
  }

  .nav {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .nav-item {
    margin-right: calc(20px + 12 * (100vw / 1920));
    font-weight: 600;
    font-size: calc(14px + 4 * (100vw / 1920));
    line-height: 25px;
    color: #121212;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease-in-out, opacity 0.3s ease-in-out;
    text-decoration: none;
  }

  .nav-item::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 20%;
    opacity: 0;
    width: 100%;
    height: 100%;
    border-bottom: 2px solid #41BF7B;
    box-shadow: inset 0px -1px 0px 0px #41BF7B;
    transition: all 0.3s ease;
  }

  .nav-item:hover::after {
    opacity: 1;
    bottom: -3px;
  }

  .nav-item-checked::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -3px;
    opacity: 1;
    width: 100%;
    height: 100%;
    border-bottom: 2px solid #41BF7B;
    box-shadow: inset 0px -1px 0px 0px #41BF7B;
    transition: all 0.3s ease;
  }

  .authorization-block {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .profile-img {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F49332;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: white;
    border-radius: 50%;
    cursor: pointer;
  }

  .sign-in-block,
  .sign-out-block {
    margin-left: 20px;
    width: 104px;
    height: 38px;
  }

  .mobile-munu-nav-profile-block {
    display: none;

    .profile-img {
      display: none;
      margin-right: 20px;
    }
  }

  .mobile-menu-open-img {
    display: none;
  }

  .reg-btn {
    font-weight: 600;
    font-size: calc(14px + 4 * (100vw / 1920));
    line-height: 25px;
    color: #41BF7B;
    cursor: pointer;
  }

  @media screen and (max-width: 1150px) {
    .nav {
      display: none;
    }

    .authorization-block {
      display: none;
    }

    .mobile-munu-nav-profile-block {
      display: flex;

      .profile-img {
        display: flex;
        margin-right: 20px;
      }
    }

    .mobile-menu-open-img {
      display: block;
      cursor: pointer;
    }
  }
</style>
