import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import offers from './modules/offers'
import cards from './modules/cards'
import loans from './modules/loans'
import loansBorrow from './modules/loans-borrow'
import lenderTrade from '@/store/modules/lender-trade'
import lenderOffer from '@/store/modules/lender-offer'
import borrowOffer from '@/store/modules/borrow-offer'
import borrowTrade from '@/store/modules/borrow-trade'
import homeLender from '@/store/modules/home-lender'
import homeBorrower from '@/store/modules/home-borrower'
import archiveOffer from '@/store/modules/archive-offer'
import archiveTrade from '@/store/modules/archive-trade'
import modal from '@/store/modules/modal'
import { CalculatorService, LoanRequestLimits } from '@/services/Calculator.service'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false,
    currentRoute: null,
    errorMessage: '',
    errorMessageCallback: null,
    validations: {} as LoanRequestLimits
  },
  mutations: {
    setSpinnerState(state, value) {
      state.isLoading = value
    },
    setErrorMessage(state, message) {
      state.errorMessage = message
    },
    setErrorMessageCallback(state, callback) {
      state.errorMessageCallback = callback
    },
    purgeErrorMessage(state) {
      state.errorMessage = ''
      if (state.errorMessageCallback) {
        state.errorMessageCallback?.()
        state.errorMessageCallback = null
      }
    },
    setValidations(state, validations: LoanRequestLimits): void {
      state.validations = validations
    },
    setCurrentRoute(state, route) {
      state.currentRoute = route
    }
  },
  actions: {
    async loading(context, state) {
      context.commit('setSpinnerState', state)
    },
    async setError(context, message) {
      context.commit('setErrorMessage', message)
    },
    async setErrorWithCallback(context, { message, callback }) {
      context.commit('setErrorMessage', message)
      context.commit('setErrorMessageCallback', callback)
    },
    async purgeError(context) {
      context.commit('purgeErrorMessage')
    },
    async getValidations(context) {
      const validations = await CalculatorService.getValidations()
      context.commit('setValidations', validations)
    },
    updateCurrentRoute(context, route) {
      context.commit('setCurrentRoute', route)
    }
  },
  getters: {
    isLoading(state) {
      return state.isLoading
    },
    errorMessage(state) {
      return state.errorMessage
    },
    validationRules(state): LoanRequestLimits {
      return state.validations
    }
  },
  modules: {
    auth,
    offers,
    cards,
    loans,
    loansBorrow,
    lenderTrade,
    lenderOffer,
    borrowTrade,
    borrowOffer,
    homeLender,
    homeBorrower,
    archiveOffer,
    archiveTrade,
    modal
  }
})
