
import { mapActions, mapGetters, mapState } from 'vuex'
import BaseModal from '../../components/base/BaseModal.vue'
import BaseButton from '../../components/base/BaseButton.vue'
import LoanService from '@/services/Loan'
import { settings } from '@/services/Settings'
import {
  Consent,
  ConsentType,
  LoanRequest,
  LoanRequestStatus,
  LoanRequestType, StorageKey,
  TradeStatus,
  UserStatus
} from '@/models'
import { Utils } from '@/helpers/utils'
import BaseTable from '@/components/base/BaseTable.vue'
import ActionCard from '@/components/common/ActionCard.vue'
import { Trade } from '@/models/trade.class'
import TradeService from '@/services/Trade'
import BaseBadge from '@/components/base/BaseBadge.vue'
import BaseCurrency from '@/components/base/BaseCurrency.vue'
import OffersService from '@/services/Offers'
import VueContext from 'vue-context'
import { BiometryStatus, VerificationStatus } from '@/models/biometry-status.enum'
import MsiService from '@/services/Msi'
import { ValidationObserver } from 'vee-validate'
import { CalculatorService } from '@/services/Calculator.service'
import BasePagination from '@/components/base/BasePagination.vue'
import BaseSelect from '@/components/base/BaseSelect.vue'

const requestColumns = [
  {
    name: 'amount',
    localization: 'borrower.request.amount'
  },
  {
    name: 'period_days_mod',
    localization: 'borrower.request.period_days'
  },
  {
    name: 'interest_rate',
    localization: 'borrower.request.percent_year'
  },
  {
    name: 'total_depth',
    localization: 'borrower.request.outcome'
  },
  {
    name: 'status',
    localization: 'borrower.request.status'
  },
  { name: 'action' }
]

const tradeColumns = [
  {
    name: 'lender_display_name',
    localization: ''
  },
  {
    name: 'signed_at',
    localization: 'message.profile.borrower.activeOffers.items.issued'
  },
  {
    name: 'amount',
    localization: 'message.profile.borrower.activeOffers.items.amount'
  },
  {
    name: 'period_days',
    localization: 'message.profile.borrower.activeOffers.items.term'
  },
  {
    name: 'interest_rate',
    localization: 'message.profile.borrower.activeOffers.items.percent'
  },
  {
    name: 'initial_debt',
    localization: 'message.profile.borrower.activeOffers.items.income'
  },
  {
    name: 'due_date',
    localization: 'message.profile.borrower.activeOffers.items.issuedBy'
  },
  {
    name: 'total_penalty',
    localization: 'message.profile.borrower.activeOffers.items.peni'
  },
  {
    name: 'remaining_debt',
    localization: 'message.profile.borrower.activeOffers.items.totalAmount'
  },
  {
    name: 'last_claim_signed_at',
    localization: ''
  },
  {
    name: 'last_legal_action_signed_at',
    localization: ''
  },
  {
    name: 'status',
    localization: 'message.profile.borrower.activeOffers.items.status'
  },
  { name: 'action' }
]

export default {
  name: 'BorrowerProfile',
  components: {
    BaseSelect,
    BasePagination,
    BaseCurrency,
    BaseBadge,
    BaseTable,
    BaseModal,
    BaseButton,
    VueContext,
    ActionCard,
    ValidationObserver
  },
  data() {
    return {
      outcome: null,
      selectedTradeDueDate: null,
      isPayModalVisible: false,
      isPayUnavailableModalVisible: false,
      consentRoutesMap: new Map([[
        'bank_report', 'scoring-agreement'
      ]]),
      request: new LoanRequest(),
      payment: new Trade(),
      isPayBtnLoading: false,
      loansItems: [],
      borrowerLoanItems: [],
      expectedOutcome: Utils.calculateShortOutcome,
      isOfferMenuOpen: null,
      amount: null,
      paymentInfo: null,
      removeOfferConfirm: null,
      isBiometryProcessing: false,
      isBiometryConfirmation: false,
      verificationStatus: VerificationStatus.NONE,
      showBlockModal: false,
      isCalculating: false,
      loansFilters: {},
      offersFilters: {},
      loansStatuses: [TradeStatus.ACTIVE, TradeStatus.NEED_SIGN, TradeStatus.OVERDUE],
      offersStatuses: [LoanRequestStatus.ACTIVE, LoanRequestStatus.DRAFT]
    }
  },
  async created() {
    if (!this.$route.query?.['save-page']) {
      this.$store.dispatch('borrowTrade/resetPage')
    }
    if (this.$route.query?.biometric === 'success') {
      this.verificationStatus = VerificationStatus.SUCCESS
    }
    if (this.$route.query?.biometric === 'failed') {
      this.verificationStatus = VerificationStatus.ERROR
    }
    if (this.$route.query?.biometric === 'confirmation-required') {
      this.verificationStatus = VerificationStatus.CONFIRM
    }
    if (this.$route.query?.biometric === 'biometry-failed-delay-block') {
      this.showBlockModal = true
    }
    if (this.$route.query?.biometric === 'biometric=biometry-failed-instant-block') {
      localStorage.removeItem(StorageKey.ACCESS)
      await this.$router.push({
        name: 'SignIn',
        params: { isBlocked: 'true' }
      })
    }
    await this.resetOffersFiltersAction()
    await this.$store.dispatch('borrowTrade/setPage')
    this.loansFilters = { ...this.tradeFilters }
    this.offersFilters = { ...this.offerFilters }
  },
  watch: {
    request: {
      handler: async function() {
        const {
          amount,
          interest_rate,
          period_days
        } = this.request
        // this.outcome = Utils.calculateShortOutcome(+amount, +interest_rate, this.request.period_type === LoanRequestType.LONG ? +period_days * 30 : +period_days)
        this.outcome = null
        this.debounceCalculateResult(amount, interest_rate, period_days)
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      applyLoansFiltersAction: 'borrowTrade/applyFilters',
      resetLoansFiltersAction: 'borrowTrade/resetFilters',
      applyOffersFiltersAction: 'borrowOffer/applyFilters',
      resetOffersFiltersAction: 'borrowOffer/resetFilters'
    }),
    async showMoreLoans(page: number): Promise<void> {
      await this.$store.dispatch('borrowTrade/setPage', page)
    },
    async showMoreRequests() {
      await this.$store.dispatch('borrowOffer/setPage', this.borrowOffer.page + 1)
    },
    openTransactions({ id }) {
      this.$router.push({
        name: 'loan',
        params: { id }
      })
    },
    async openPayModal(data: Trade) {
      if (!!data?.due_date && new Date() < this.parseDate(data?.due_date)) {
        this.selectedTradeDueDate = data?.due_date
        this.isPayModalVisible = false
        this.isPayUnavailableModalVisible = true
        return
      }
      this.payment = data
      this.paymentInfo = await TradeService.getPayInfo(data.id)
      this.isPayModalVisible = true
      this.isPayUnavailableModalVisible = false
      this.selectedTradeDueDate = null
    },
    closePayModal() {
      if (!this.isPayBtnLoading) {
        this.isPayModalVisible = false
      }
      this.paymentInfo = null
      this.amount = null
    },
    closePayUnavailableModal() {
      this.isPayUnavailableModalVisible = false
    },
    async changeStatus(request: LoanRequest, status: 'active' | 'draft') {
      try {
        this.$refs.requestMenu.close()
        await this.$store.dispatch('changeBorrowOfferStatus', {
          request,
          status
        })
        await this.$store.dispatch('borrowOffer/setPage')
      } catch (e) {

      }
    },
    async removeOffer() {
      try {
        this.isOfferMenuOpen = null
        await OffersService.removeBorrowRequestStatus(this.removeOfferConfirm)
        await this.$store.dispatch('borrowOffer/setPage', 1)
        this.removeOfferConfirm = null
      } catch (e) {

      }
    },
    async startPayment() {
      this.isPayBtnLoading = true
      try {
        await TradeService.pay(this.payment.id, { amount: this.amount })
        this.isPayBtnLoading = false
        this.closePayModal()
        this.$emit('successPayment')
      } catch (error) {
        this.isPayBtnLoading = false
        this.closePayModal()
      }
    },
    getConsentRoute(consentType: ConsentType): string {
      return this.profile.additional_info_verified ? this.consentRoutesMap.get(consentType) : 'change-profile'
    },
    confirmRemove(id: number) {
      this.removeOfferConfirm = id
      this.$refs.requestMenu.close()
    },
    parseDate(value: string): Date {
      const dateParts = value.split('.')
      return new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]))
    },
    async createLoanBorrowRequest() {
      const {
        amount,
        interest_rate,
        period_days
      } = this.request
      try {
        this.isPayBtnLoading = true
        await LoanService.createBorrowRequest({
          amount,
          interest_rate,
          period_days
        })
        await this.$store.dispatch('borrowOffer/setPage', 1)
        this.request = new LoanRequest()
        this.isPayBtnLoading = false
        this.$toast.success(this.$t('message.profile.lender.offer.toastMsg'))
      } catch (error) {
        this.isPayBtnLoading = false
      }
    },
    goToScoringAgreement() {
      if (this.profile.status === UserStatus.NEW) {
        this.$router.push({ name: 'scoringAgreement' })
      } else {
        this.$router.push({ name: 'change-profile' })
      }
    },
    goToCards() {
      this.$router.push('cards')
    },
    goToSettings() {
      this.$router.push('settings')
    },
    goToVerifyEmail() {
      this.$router.push('change-contact-info')
    },
    async processBiometry() {
      try {
        this.isBiometryProcessing = true
        const { url } = await MsiService.getMsiBiometryLink()
        window.location.href = url
      } catch (e) {
        console.log(e)
      }
    },
    async confirmBiometry() {
      try {
        const track = this.$route.query?.track
        this.isBiometryConfirmation = true
        await MsiService.confirmMsiBiometry(track)
        this.verificationStatus = this.VerificationStatus.SUCCESS
      } catch (e) {
        this.verificationStatus = this.VerificationStatus.ERROR
      } finally {
        this.isBiometryConfirmation = false
        await this.$store.dispatch('updateProfile')
      }
    },
    closeBiometryModal() {
      this.$router.push('profile')
      this.verificationStatus = this.VerificationStatus.NONE
    },
    debounceCalculateResult: Utils.debounce(async function(amount, interest_rate, period_days) {
      const isValid = await this.$refs.observer.validate()
      const isFieldsFulfilled = this.request.amount && this.request.interest_rate && this.request.period_days
      if (isValid && isFieldsFulfilled) {
        this.isCalculating = true
        const result = await CalculatorService.calculate({
          amount,
          interest_rate,
          period_days
        })
        this.isCalculating = false
        this.outcome = result.total_amount
      }
    }, 500),
    async applyLoansFilters() {
      const requestFilters = {
        ...this.loansFilters
      }
      await this.applyLoansFiltersAction(requestFilters)
    },
    async resetLoansFilters() {
      this.loansFilters.status = null
      await this.resetLoansFiltersAction()
    },
    async applyOffersFilters() {
      const requestFilters = {
        ...this.offersFilters
      }
      await this.applyOffersFiltersAction(requestFilters)
    },
    async resetOffersFilters() {
      this.offersFilters.status = null
      await this.resetOffersFiltersAction()
    }
  },
  computed: {
    canPay() {
      if (!this.amount || this.amount >= this.paymentInfo.amount_due) {
        return true
      }
      return this.amount && this.paymentInfo.amount_due && this.paymentInfo.amount_due - this.amount > 0.1
    },
    amountIsTooSmall() {
      return this.amount < 0.1
    },
    UserStatus() {
      return UserStatus
    },
    TradeStatus() {
      return TradeStatus
    },
    requestColumns() {
      return requestColumns
    },
    tradeColumns() {
      return tradeColumns
    },
    LoanRequestType() {
      return LoanRequestType
    },
    ...mapGetters(['validationRules']),
    ...mapState(['borrowTrade']),
    ...mapState(['borrowOffer']),
    ...mapGetters('borrowTrade', ['tradeList', 'tradeFilters']),
    ...mapGetters('borrowOffer', ['offerList', 'offerFilters']),
    ...mapGetters([
      'profile'
    ]),
    isPayBtnDisabled() {
      return !this.amount || this.isPayBtnLoading
    },
    requestAmountPlaceholder() {
      const amountFrom = this.validationRules.MIN_LOAN_REQUEST_AMOUNT
      const amountTo = this.validationRules.MAX_LOAN_REQUEST_AMOUNT
      return `${this.$t('message.profile.lender.offer.amount.placeholderStart')} ${amountFrom} ${this.$t('message.profile.lender.offer.amount.placeholderEnd')} ${amountTo}`
    },
    amountShortTermPlaceholder() {
      return `${this.$t('message.profile.lender.offer.term.placeholderStart')} ${this.validationRules.MIN_LOAN_REQUEST_PERIOD_DAYS} ${this.$t('message.profile.lender.offer.term.placeholderEnd')} ${this.validationRules.MAX_LOAN_REQUEST_PERIOD_DAYS} ${this.$t('message.profile.lender.offer.term.days')}`
    },
    requestPercentPlaceholder() {
      const percentFrom = this.validationRules.MIN_LOAN_REQUEST_INTEREST_RATE
      const percentTo = this.validationRules.MAX_LOAN_REQUEST_INTEREST_RATE
      return `${this.$t('message.profile.lender.offer.percent.placeholderStart')} ${percentFrom} ${this.$t('message.profile.lender.offer.percent.placeholderEnd')} ${percentTo}`
    },
    requestAmountRules() {
      return 'isBetween:' + this.validationRules.MIN_LOAN_REQUEST_AMOUNT + ',' + this.validationRules.MAX_LOAN_REQUEST_AMOUNT + ',' + 'BYN'
    },
    requestPeriodRules() {
      return 'isBetween:' + this.validationRules.MIN_LOAN_REQUEST_PERIOD_DAYS + ',' + this.validationRules.MAX_LOAN_REQUEST_PERIOD_DAYS + ',' + 'дней'
    },
    interestRateRules() {
      return 'isBetween:' + this.validationRules.MIN_LOAN_REQUEST_INTEREST_RATE + ',' + this.validationRules.MAX_LOAN_REQUEST_INTEREST_RATE + ',' + '%'
    },
    missingConsents(): Consent[] {
      return this.profile.consents.filter(consent => !consent.status && consent.type !== ConsentType.BANK_REPORT)
    },
    hasEmptyFields(): boolean {
      return !this.request.amount || !this.request.period_days || !this.request.interest_rate || this.errors.items?.length > 0
    },
    requestOverload(): boolean {
      return +this.outcome > (+this.request.amount * 3)
    },
    VerificationStatus(): typeof VerificationStatus {
      return VerificationStatus
    },
    isScoringAgreementStatusOk(): boolean {
      return this.profile.consents.find(consent => consent.type === ConsentType.BANK_REPORT)?.status
    },
    biometryStatus() {
      return BiometryStatus
    },
    showCardsTitle() {
      return !this.profile.for_block
    },
    msiLimitExceeded() {
      return this.profile.biometry_attempts === this.profile.msi_biometry_max_attempts
    },
    showActionCardsBlock() {
      return this.profile.msi_biometry === this.biometryStatus.REQUIRED || !this.isScoringAgreementStatusOk || !this.profile.has_bank_card || !this.profile.email_verified || this.profile?.bepaid_checkout_notification?.show
    },
    migrationTime() {
      return this.$options.filters.timeDate(this.profile?.bepaid_checkout_notification?.datetime, '.')
    }
  }
}
