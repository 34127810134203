import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import SignIn from '../views/SignIn.vue'
import Msi from '../views/MSI/index.vue'
import SignUpV2 from '../views/MSI/SignUpV2.vue'
import PasswordRecovery from '../views/PasswordRecovery/index.vue'
import NotFound from '../views/NotFound.vue'
import Profile from '../views/Profile.vue'
import Bid from '../views/Bid.vue'
import Main from '../views/Main.vue'
import Cards from '../views/Cards/Cards.vue'
import Settings from '../views/Settings/index.vue'
import Archive from '../views/Archive/index.vue'
import Notifications from '../views/Notifications.vue'
import Messages from '../views/Messages.vue'
import Contract from '../views/Contract/Contract.vue'
import Contacts from '../views/StaticPages/Contacts.vue'
import Legislation from '../views/StaticPages/Legislation.vue'
import GetLoan from '../views/StaticPages/GetLoan.vue'
import Investing from '../views/StaticPages/Investing.vue'
import Questions from '../views/StaticPages/Questions/index.vue'
import About from '../views/StaticPages/About.vue'
import Arbitration from '../views/StaticPages/Arbitration.vue'
import ChangePassword from '../views/Settings/ChangePassword.vue'
import ScoringAgreement from '../views/ScoringAgreement/index.vue'
import Loan from '../views/Profile/Loan.vue'
import Documents from '../views/StaticPages/Documents.vue'
import News from '../views/News/index.vue'
import { StorageKey } from '@/models'
import ChangeProfile from '@/views/Settings/ChangeProfile.vue'
import ChangeContactInfo from '@/views/Settings/ChangeContactInfo.vue'
import ConsentManagement from '@/views/Settings/ConsentManagement.vue'
import CreateClaim from '@/views/Claim/index.vue'
import Claim from '@/views/Claim/Claim.vue'
import LegalAction from '@/views/Claim/LegalAction.vue'
import MsiUpdateInfo from '@/views/MsiUpdate/MsiUpdateInfo.vue'
import ConfirmationEmailResult from '@/views/StaticPages/ConfirmationEmailResult.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/signIn',
  name: 'SignIn',
  component: SignIn,
  props: route => ({ isBlocked: route.query.isBlocked })
}, {
  path: '/404',
  name: 'NotFound',
  component: NotFound
}, {
  path: '/loan/:id',
  name: 'loan',
  component: Loan,
  meta: {
    isGuarded: true
  }
}, {
  path: '/news',
  name: 'news',
  component: News
}, {
  path: '/documents',
  name: 'documents',
  component: Documents
}, {
  path: '/arbitration',
  name: 'arbitration',
  component: Arbitration
}, {
  path: '/about',
  name: 'about',
  component: About
}, {
  path: '/questions',
  name: 'questions',
  component: Questions
}, {
  path: '/investing',
  name: 'investing',
  component: Investing
}, {
  path: '/get-loan',
  name: 'getLoan',
  component: GetLoan
}, {
  path: '/legislation',
  name: 'legislation',
  component: Legislation
}, {
  path: '/contacts',
  name: 'contacts',
  component: Contacts
}, {
  path: '/',
  name: 'main',
  component: Main
}, {
  path: '/scoring-agreement',
  name: 'scoringAgreement',
  component: ScoringAgreement,
  meta: {
    isGuarded: true
  }
}, {
  path: '/contract',
  name: 'contract',
  component: Contract,
  meta: {
    isGuarded: true
  }
}, {
  path: '/messages',
  name: 'messages',
  component: Messages,
  meta: {
    isGuarded: true
  }
}, {
  path: '/notifications',
  name: 'notifications',
  component: Notifications,
  meta: {
    isGuarded: true
  }
}, {
  path: '/archive',
  name: 'archive',
  component: Archive,
  meta: {
    isGuarded: true
  }
}, {
  path: '/change-password',
  name: 'change-password',
  component: ChangePassword,
  meta: {
    isGuarded: true
  }
}, {
  path: '/change-profile',
  name: 'change-profile',
  component: ChangeProfile,
  meta: {
    isGuarded: true
  }
}, {
  path: '/change-contact-info',
  name: 'change-contact-info',
  component: ChangeContactInfo,
  meta: {
    isGuarded: true
  }
}, {
  path: '/consent-management',
  name: 'consent-management',
  component: ConsentManagement,
  meta: {
    isGuarded: true
  }
}, {
  path: '/settings',
  name: 'settings',
  component: Settings,
  meta: {
    isGuarded: true
  }
}, {
  path: '/create-claim/:id',
  name: 'create-claim',
  component: CreateClaim,
  meta: {
    isGuarded: true
  }
}, {
  path: '/sign-claim/:id',
  name: 'sign-claim',
  component: Claim,
  meta: {
    isGuarded: true
  }
}, {
  path: '/sign-legal-action/:id',
  name: 'sign-legal-action',
  component: LegalAction,
  meta: {
    isGuarded: true
  }
}, {
  path: '/cards',
  name: 'cards',
  component: Cards,
  meta: {
    isGuarded: true
  }
}, {
  path: '/profile',
  name: 'profile',
  component: Profile,
  meta: {
    isGuarded: true
  }
}, {
  path: '/bid',
  name: 'bid',
  component: Bid,
  meta: {
    isGuarded: true
  }
}, {
  path: '/passwordRecovery',
  name: 'PasswordRecovery',
  component: PasswordRecovery
}, {
  path: '/msi',
  name: 'msi',
  component: Msi
}, {
  path: '/msi-info',
  name: 'SignUpV2',
  component: SignUpV2
}, {
  path: '/msi-update-info',
  name: 'MsiUpdateInfo',
  component: MsiUpdateInfo
}, {
  path: '/confirm-email-result',
  name: 'confirmEmailResult',
  component: ConfirmationEmailResult
}, {
  path: '/main',
  redirect: '/'
}, {
  path: '*',
  redirect: '/404'
}]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes, // eslint-disable-next-line
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    }
    return {
      x: 0,
      y: 0
    }
  }
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem(StorageKey.ACCESS)
  const isAuth = !!token
  store.dispatch('updateCurrentRoute', {
    path: to.path,
    query: to.query
  })

  function guardRoute(to, from, next, isAuth) {
    isAuth ? next() : next({
      path: 'signIn',
      query: { redirect: to.fullPath }
    })
  }

  if (to.meta?.isGuarded) {
    guardRoute(to, from, next, isAuth)
  } else {
    next()
  }
})

export default router
