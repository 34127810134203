
import BaseDatepicker from '../../components/base/BaseDatepicker.vue'
import BaseSelect from '../../components/base/BaseSelect.vue'
import BaseRadioButton from '../../components/base/BaseRadioButton.vue'
import BasePasswordInput from '../../components/base/BasePasswordInput.vue'
import BaseTextInput from '../../components/base/BaseTextInput.vue'
import BaseTextareaInput from '../../components/base/BaseTextareaInput.vue'

import CONSTANTS from '../../constants/constants'

import signUpValidationMixin from '../../mixins/signUp/signUpValidationMixin'

import { mapGetters } from 'vuex'
import AuthService from '@/services/Auth'
import MsiService from '@/services/Msi'
import Vue from 'vue'
import { MsiResponse, StorageKey } from '@/models'
import router from '@/router'
import { Roles } from '@/models/roles.enum'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'
import { Utils } from '@/helpers/utils'
import formatFileName = Utils.formatFileName
import formatFileSize = Utils.formatFileSize
import validateFile = Utils.validateFile
import BaseModal from '@/components/base/BaseModal.vue'

const REQUIRED_FIELDS = [
  'birthplace',
  'citizenship',
  'date_of_birth',
  'date_of_expiry',
  'date_of_issue',
  'document_type',
  'email',
  'first_name',
  'id_number',
  'issued_by',
  'last_name',
  'codeword',
  'phone',
  'otp',
  'password'
]

const JOB_INFO_FIELDS = [
  'employment_type',
  'employment_area',
  'company_name',
  'position',
  'experience'
]
const LENDER_JOB_INFO_FIELDS = [
  'employment_type',
  'employment_area',
  'company_name',
  'position'
]
const EXTRA_INFO_FIELDS = [
  'education',
  'family_status',
  'number_of_dependents',
  'monthly_income'
]

const EXTRA_INFO_BOOL = [
  'driving_license',
  'car_owned',
  'real_estate_owned',
  'need_to_refund_expenses'
]

const LIVING_ADDRESS = [
  'country_code',
  'house_number',
  'street_name',
  'street_type',
  'locality_type',
  'locality_name',
  'region'
]

const lenderAcceptableDocTypes = [1, 2, 3, 4, 5]
const borrowerAcceptableDocTypes = [1, 3]

const SignUp = Vue.extend({
  name: 'SignUp',
  mixins: [signUpValidationMixin],
  components: {
    BaseModal,
    BaseCheckbox,
    BaseDatepicker,
    BaseSelect,
    BaseRadioButton,
    BasePasswordInput,
    BaseTextInput,
    BaseTextareaInput
  },
  async created() {
    const {
      code,
      verification_id
    } = this.$route.query

    if (verification_id) {
      this.$router.push({
        name: 'MsiUpdateInfo',
        query: {
          code,
          verification_id
        }
      })
      return
    }

    code ? await this.$store.dispatch('getMsiProfileV2', code) : this.$router.push({ name: 'main' })

    this.userData = { ...this.msiData }
    this.userData.phone = this.userData.phone.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3 $4 $5')
    this.combinedPassportNumber = this.userData.series + this.userData.number
    if (!borrowerAcceptableDocTypes.includes(this.userData.document_type) && this.userData.role === Roles.BORROWER) {
      this.wrongDocType = true
      return
    }
    if (!lenderAcceptableDocTypes.includes(this.userData.document_type) && this.userData.role === Roles.LENDER) {
      this.wrongDocType = true
      return
    }
    if (this.getAge(this.userData.date_of_birth) < 18) {
      this.wrongDOB = true
    }
  },
  data(): any {
    return {
      CONSTANTS,
      isAddressLivingAndRegistrationTheSame: false,
      isDataFullAndCorrect: true,
      isAgreeWithServiceRules: false,
      personal_data_consent: false,
      isAgreeWithServiceAgreement: false,
      isAgreeToGetBankReport: true,
      work_info: {
        employment_type: null,
        employment_area: null,
        company_name: null,
        position: null,
        experience: null,
        organization_phone: ''
      },
      extra_user_info: {
        education: null,
        family_status: null,
        driving_license: null,
        car_owned: null,
        real_estate_owned: null,
        number_of_dependents: 0,
        need_to_refund_expenses: null,
        monthly_income: ''
      },
      userData: {
        role: Roles.BORROWER,
        codeword: '',
        otp: ''
      } as MsiResponse,
      living_address_str: '',
      isSignUpBtnLoading: false,
      combinedPassportNumber: '',
      living_address: {
        address_str: '',
        apartment_number: '',
        country_code: '',
        created_at: '',
        house_number: '',
        house_unit: '',
        locality_name: '',
        locality_type: '',
        region: '',
        street_name: '',
        street_type: '',
        type: ''
      },
      wrongDocType: false,
      wrongDOB: false,
      car_owned_files: null,
      real_estate_owned_files: null,
      carFileError: null,
      realEstateFileError: null,
      error: '',
      successModal: false,
      isWorkInfoBlockDisabled: false
    }
  },
  computed: {
    Roles() {
      return Roles
    },
    ...mapGetters(['msiData', 'signUpDisabledFields', 'isLoading']),
    fileValidationRules() {
      return 'required|ext:pdf,jpeg,jpg,png,jip,tiff|size:10240'
    },
    isAddCarFilesDisabled() {
      return this.car_owned_files?.length === 4
    },
    isAddRealEstateFilesDisabled() {
      return this.real_estate_owned_files?.length === 4
    }
  },
  methods: {
    // TODO do refactor
    validate() {
      if (this.userData.role === Roles.BORROWER) {
        for (const key of [...EXTRA_INFO_FIELDS, ...EXTRA_INFO_BOOL]) {
          if (this.extra_user_info[key] === undefined || this.extra_user_info[key] === null || this.extra_user_info[key] === '') {
            return key
          }
        }
        if (this.extra_user_info.car_owned) {
          if (!this.car_owned_files || this.car_owned_files?.length < 2) {
            return 'car_owned_files'
          }
        }
        if (this.extra_user_info.real_estate_owned) {
          if (!this.real_estate_owned_files || this.real_estate_owned_files?.length === 0) {
            return 'real_estate_owned_files'
          }
        }
        if (!borrowerAcceptableDocTypes.includes(this.userData.document_type)) {
          this.wrongDocType = true
          return 'wrong_doc_type'
        }
      }
      if (this.userData.role === Roles.BORROWER) {
        for (const key of JOB_INFO_FIELDS) {
          if (this.work_info[key] === undefined || this.work_info[key] === null || this.work_info[key] === '') {
            return key
          }
        }
      }
      if (this.userData.role === Roles.LENDER) {
        for (const key of LENDER_JOB_INFO_FIELDS) {
          if (this.work_info[key] === undefined || this.work_info[key] === null || this.work_info[key] === '') {
            return key
          }
        }
      }
      for (const key of REQUIRED_FIELDS) {
        if (!this.userData[key]) {
          return key
        }
      }
      if (this.userData.role === Roles.LENDER) {
        if (!lenderAcceptableDocTypes.includes(this.userData.document_type)) {
          this.wrongDocType = true
          return 'wrong_doc_type'
        }
      }
      if (!this.isAddressLivingAndRegistrationTheSame) {
        for (const key of LIVING_ADDRESS) {
          if (!this.living_address[key]) {
            return key
          }
        }
      }
      if (!this.isAgreeWithServiceRules || !this.isAgreeWithServiceAgreement) {
        return 'accept_agreements'
      }
      return this.errors.items.length > 0 ? 'other' : ''
    },
    checkDocType() {
      this.userData.role = Roles.BORROWER
      setTimeout(() => {
        if (!borrowerAcceptableDocTypes.includes(this.userData.document_type) && this.userData.role === Roles.BORROWER) {
          this.wrongDocType = true
        }
      })
    },
    checkLenderDocType() {
      this.userData.role = Roles.LENDER
      setTimeout(() => {
        if (!lenderAcceptableDocTypes.includes(this.userData.document_type) && this.userData.role === Roles.BORROWER) {
          this.wrongDocType = true
        }
      })
    },
    onCarFilesChange(event) {
      this.carFileError = null
      this.car_owned_files = this.car_owned_files ?? []
      const currentFiles: File[] = this.car_owned_files
      const newFiles: File[] = [...event.target.files]
      newFiles.forEach(file => {
        if (currentFiles.findIndex(f => file.name === f.name) !== -1) {
          this.carFileError = 'Один из файлов уже добавлен'
          return
        }
        if (!validateFile(file)) {
          this.carFileError = 'Не все файлы из выбранных добавлены. Проверьте размер и расширение'
          return
        }
        if (currentFiles.length === 4) {
          this.carFileError = 'Не все файлы из выбранных добавлены. Максимальное количество файлов - 4'
          return
        }
        currentFiles.push(file)
      })
    },
    onEstateFilesChange(event) {
      this.realEstateFileError = null
      this.real_estate_owned_files = this.real_estate_owned_files ?? []
      const currentFiles: File[] = this.real_estate_owned_files ?? []
      const newFiles: File[] = [...event.target.files]
      newFiles.forEach(file => {
        if (currentFiles.findIndex(f => file.name === f.name) !== -1) {
          this.realEstateFileError = 'Один из файлов уже добавлен'
          return
        }
        if (!validateFile(file)) {
          this.realEstateFileError = 'Не все файлы из выбранных добавлены. Проверьте размер и расширение'
          return
        }
        if (currentFiles.length === 4) {
          this.realEstateFileError = 'Не все файлы из выбранных добавлены. Максимальное количество файлов - 4'
          return
        }
        currentFiles.push(file)
      })
    },
    removeEstateFile(name) {
      this.real_estate_owned_files = this.real_estate_owned_files.filter(file => file.name !== name)
    },
    removeCarFile(name) {
      this.car_owned_files = this.car_owned_files.filter(file => file.name !== name)
    },
    async getOtpCode() {
      try {
        const phone = this.userData.phone
          .replace('+', '')
          .replace(/ /g, '')
          .replace('(', '')
          .replace(')', '')
        await AuthService.getOtpCode(phone)
        this.$toast.success(this.$t('message.signIn.otpToastMsg'))
      } catch (error) {
        this.$router.push({ name: 'SignIn' })
      }
    },
    getAge(dateString) {
      const today = new Date()
      const birthDate = new Date(dateString)
      let age = today.getFullYear() - birthDate.getFullYear()
      const m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }
      return age
    },
    async signUp() {
      this.error = this.validate()
      if (this.error) {
        return
      }
      this.isSignUpBtnLoading = true
      const otp = +this.userData.otp
      const login = this.userData.phone
      const living_address_str = this.isAddressLivingAndRegistrationTheSame ? this.userData.address_str : this.living_address_str
      const extra_info = this.userData.role !== Roles.LENDER ? this.extra_user_info : undefined
      const personal_data_consent = this.personal_data_consent
      const payload = {
        ...this.userData,
        living_address_eq_reg: this.isAddressLivingAndRegistrationTheSame,
        work_info: {
          ...this.work_info,
          position: this.work_info.position ? this.work_info.position === '-' ? null : this.work_info.position : null
        },
        living_address: !this.isAddressLivingAndRegistrationTheSame ? this.living_address : this.userData.reg_address,
        otp,
        login,
        extra_info,
        living_address_str,
        personal_data_consent
      }
      payload.phone = payload.phone
        .replace('+', '')
        .replace(/ /g, '')
        .replace('(', '')
        .replace(')', '')

      try {
        if (this.extra_user_info.car_owned) {
          await MsiService.sendFiles({
            msi_personal_info_id: this.userData.msi_personal_info_uuid,
            type: 'car',
            files: this.car_owned_files
          })
        }
        if (this.extra_user_info.real_estate_owned) {
          await MsiService.sendFiles({
            msi_personal_info_id: this.userData.msi_personal_info_uuid,
            type: 'real_estate',
            files: this.real_estate_owned_files
          })
        }

        const {
          access,
          refresh
        } = await MsiService.signUp(payload)
        await localStorage.setItem(StorageKey.ACCESS, access)
        await localStorage.setItem(StorageKey.REFRESH, refresh)
        this.isSignUpBtnLoading = false
        if (this.userData.role === Roles.LENDER) {
          this.successModal = true
          return
        }
        router.push({ name: 'profile' })
      } catch (error) {
        this.isSignUpBtnLoading = false
      }
    },
    goToMain() {
      this.$router.push({ name: 'main' })
    },
    async viewPersonalConsent(): Promise<void> {
      try {
        const data = await MsiService.loadPersonalConsentPreview(this.userData.msi_personal_info_uuid)
        Utils.downloadBlob(data, 'personal-consent-example.pdf')
      } catch (e) {

      }
    },
    onTypeOfEmploymentChanged(value) {
      if (['tmp_unemployed', 'pensioner', 'student'].includes(value)) {
        this.work_info.company_name = this.work_info.position = '-'
        this.work_info.employment_area = 'not_indicated'
        this.isWorkInfoBlockDisabled = true
        this.work_info.experience = 0
        return
      }
      this.work_info.company_name = this.work_info.employment_area = this.work_info.position = null
      this.isWorkInfoBlockDisabled = false
    },
    closeModal() {
      this.successModal = false
      router.push({ name: 'profile' })
    },
    formatFileSize,
    formatFileName
  }
})

export default SignUp
