import { LoanRequestType, TradeStatus, TransactionStatus } from '@/models'

const ru = {
  'Enter amount': 'Введите сумму',
  'Enter percent': 'Введите проценты',
  'Enter period': 'Введите перод',
  'You have accepted the loan application, wait for the borrower to sign the loan agreement':
    'Вы приняли заявку на выдачу займа, ожидайте подписание договора займа заемщиком до 23:59',
  'Your claim was sent to borrower':
    'Ваша претензия направлена заемщику и в третейский суд',
  'A you sure to accept this offer?': 'Желаете подписать договор займа?',
  'Load agreement': 'Скачать договор',
  Borrower: 'Заемщик',
  'Registration address': 'Адрес регистрации',
  'You can\'t edit profile settings':
    'Обновление анкеты будет доступно при следующем запросе на подписание "Согласия на предоставление отчета в Кредитный регистр"',
  'Update data through MSI': 'Актуализировать персональные данные через МСИ',
  Yes: 'ДА',
  No: 'НЕТ',
  Back: 'Назад',
  'Main data': 'Основные данные',
  'Personal data verification': 'Верификация данных МСИ',
  'Last Name': 'Фамилия',
  'First Name': 'Имя',
  'Middle Name': 'Отчество',
  'Birth Date': 'Дата рождения',
  Citizenship: 'Гражданство',
  Confirm: 'Подтвердить',
  Gender: 'Пол',
  Birthplace: 'Место рождения',
  Number: 'Номер документа',
  'Document type': 'Тип документа',
  Series: 'Серия документа',
  'Issue date': 'Дата выдачи',
  'Expiry date': 'Годен до',
  'Issued by': 'Выдан',
  'Passport data': 'Паспортные данные',
  'MSI data has been verified': 'Данные успешно актуализированы',
  Country: 'Страна',
  Region: 'Область',
  'Locality type': 'Тип населенного пункта',
  'Locality name': 'Название населенного пункта',
  'Street type': 'Тип локации',
  'Street name': 'Название локации',
  'House number': 'Номер дома',
  'House unit': 'Корпус',
  'Apartment number': 'Квартира',
  'Living address': 'Адрес проживания',
  Phone: 'Телефон',
  Email: 'Электронная почта',
  'Contact info': 'Контактная информация',
  'Select phone': 'Выберите телефон',
  'Select email': 'Выберите электронную почту',
  'Enter email': 'Введите электронную почту',
  Male: 'Мужской',
  Female: 'Женский',
  'Load claim': 'Скачать претензию',
  'Wrong code': 'Неверный код',
  category: {
    title: 'Выберите категорию',
    other: 'Прочее',
    concluded_loan_transactions: 'Заключенные сделки займа'
  },
  sign_up_error: {
    pre: 'Обязательно: ',
    error: {
      birthplace: 'Место рождения',
      citizenship: 'Гражданство',
      date_of_birth: 'Дата рождения',
      date_of_expiry: 'Срок действия документа',
      date_of_issue: 'Дата выдачи',
      document_type: 'Основной документ',
      email: 'Email',
      first_name: 'Имя',
      id_number: 'Идентификационный номер (ID)',
      issued_by: 'Кем выдан',
      last_name: 'Фамилия',
      codeword: 'Кодовое слово',
      phone: 'Телефон',
      otp: 'Код СМС',
      password: 'Пароль',
      employment_type: 'Вид занятости',
      employment_area: 'Сфера занятости',
      company_name: 'Наименование организации',
      position: 'Должность',
      experience: 'Стаж работы в данной организации (мес.)',
      education: 'Образование',
      family_status: 'Семейное положение',
      number_of_dependents: 'Количество иждивенцев',
      monthly_income: 'Общий ежемесячный доход в BYN',
      country_code: 'Код страны',
      house_number: 'Номер дома',
      house_unit: 'Корпус',
      street_name: 'Название локации',
      locality_name: 'Населенный пункт',
      region: 'Область',
      street_type: 'Тип локации',
      locality_type: 'Тип населенного пункта',
      required_links: 'Ознакомьтесь с условиями и правилами перейдя по ссылке',
      accept_agreements:
        'Дайте согласие на условия окзания услуг и политикой обработки данных',
      wrong_doc_type: 'Невозможна регистрация с Вашим типом документа',
      other: 'Проверьте поля с ошибками',
      living_address_required: 'Место жительства обязательно для заполнения',
      driving_license: 'Водительское удостоверение',
      car_owned: 'Автомобиль в собственности',
      car_owned_files: 'Технический паспорт автомобиля',
      real_estate_owned: 'Недвижимость в собственности',
      real_estate_owned_files: 'Свидетельство о регистрации недвижимого имущества',
      need_to_refund_expenses:
        'Обязанность возмещать расходы, затраченные государством на содержание детей (в детских домах, интернатах, спец.учреждениях и пр.)'
    }
  },
  Remove: 'Удалить',
  bill_file_title: 'Прикрепить копию чека об оплате третейского сбора:',
  borrower_response_file_title:
    'Копия ответа Заёмщика на Претензию (при наличии):',
  payment: {
    full_amount: 'Сумма для погашения'
  },
  error: {
    agreement_not_found: 'Не найден займ с указанным номером договора',
    password_blank: 'Вы ввели неправильный логин или пароль',
    user_wrong_credentials: 'Вы ввели неправильный логин или пароль',
    no_active_account: 'Вы ввели неправильный логин или пароль',
    user_sign_in_attempt_blocked: 'Превышено число попыток входа',
    max_loan_requests_per_day_limit_reached:
      'Вы превысили лимит публикации (снятия с публикации) заявок на главном экране в течение дня',
    max_loan_requests_limit_reached:
      'Вы превысили лимит 100 опубликованных заявок на главном экране. Для создания новой заявки снимите с публикации заявку и создайте новую.',
    max_pinned_request_reached: 'Достигнут лимит',
    otp_not_sent: 'Запросите код подтверждения через СМС',
    otp_incorrect: 'Введен некорректный СМС код',
    phone_duplicated:
      'Пользователь с текущим номером телефона уже зарегистрирован в системе',
    undefined: 'Произошла ошибка',
    max_borrow_request_reached:
      'Вы превысли максимально допустимое число займов или заявок',
    not_authenticated: 'Пользователь не авторизован',
    third_party_service_unavailable:
      'Сервис по обслуживанию карт временно недоступен, попробуйте позже',
    max_borrow_amount: 'Вы не можете взять займ больше чем на 1000 рублей',
    msi_personal_info_uuid_user_already_registered:
      'Пользователь с указанным номером телефона зарегистрирован в системе',
    claim_not_available:
      'Создание претензии недоступно так как с подачи последней прошло меньше 24 часов',
    legal_action_not_available:
      'Подача искового заявления недоступна так как с подачи последней претензии не прошло 10 рабочих дней или Вы еще не подали Претензию для текущей сделки',
    transaction_error: 'Ошибка перевода, проверьте сумму и Ваш баланс',
    phone_invalid: 'Некорректный номер телефона',
    default_card_cannot_be_deleted:
      'Активная карта не может быть удалена при наличии активных займов. Добавьте другую карту для проведения расчетов, после чего сможете удалить текущую активную карту',
    password_changer_old_password_invalid: 'Вы некорректно ввели старый пароль',
    password_changer_new_password_the_same_as_old:
      'Новый пароль совпадает со старым. Установите новый пароль',
    personal_info_updater_phone_number_already_in_use:
      'Указанный номер телефона используется в системе',
    personal_data_consent_already_revoked:
      'Вы уже отозвали согласие на обработку персональных данных',
    personal_data_consent_already_accepted:
      'Вы уже дали согласие на обработку персональных данных',
    personal_data_consent_not_accepted:
      'Вы не можете отозвать согласие на обработку персональных данных. Вы уже подписали несогласие',
    repayment_transactions_temporary_forbidden_error:
      'Платежи временно не доступны, попробуйте позже',
    max_trades_reached: 'Вы превысли максимально допустимое число займов или заявок',
    the_only_card_cannot_be_deleted_because_you_have_pending_trade:
      'Вы не можете удалить карту пока у Вас есть займы в статусе Ожидание перевода',
    card_cannot_be_switched_because_you_have_pending_trade:
      'Вы не можете сменить основную карту пока у Вас есть займы в статусе Ожидание перевода',
    msi_response_unknown_error: 'Неизвестная ошибка системы МСИ',
    default_bank_card_not_set: 'У Вас не привзяна основная банковская карта',
    another_transaction_in_pending_status_yet:
      'Предыдущая транзакция для текущего займа в процессе исполнения',
    wrong_status: 'С Вашим текущем статусом эта операция недоступна',
    additional_info_update_forbidden:
      'Вам недоступно редактирование данных, так как не истек срок согласия о предоставлении отчета в Кредитный регистр',
    AUTHENTICATION_FAILED:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    AUTHENTICATION_TIMEOUT:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    CARD_BLOCKED:
      'Ваша банковская карта заблокирована .\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    CARD_EXPIRED:
      'Срок действия вашей банковской карты истек .\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    CARD_DATA_INVALID:
      'Данные о вашей банковской карте некорректны.\nВнесите корректные данные или добавьте другую банковскую карту.',
    SYSTEM_ERROR:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    CLIENT_LOST:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    CLIENT_CANCEL:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    ATTEMPTS_EXHAUSTED:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    AUTHORIZATION_FAILED:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    FM_FAILED:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    FM_REJECTED:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    FM_REJECTED_BY_SCORE:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    LIMIT_EXCEEDED:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    CARD_REMOVED:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    REDIRECT_REQUIRED:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    REGISTERED_BY_OTHER_CLIENT:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    INSUFFICIENT_FUNDS:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    CARD_FAULT:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    CARD_LOST_OR_STOLEN:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    CARD_PAGE_DECLINED:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    CARD_PAGE_TIMEOUT:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    CPA_REJECTED:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    CPA_FAILED:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    REGISTER_PAYMENT_FAILED:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    UNKNOWN:
      'Ваша банковская карта не прошла верификацию.\nДобавьте другую банковскую карту или обратитесь в банк, обслуживающий вашу карту.',
    claim_with_max_requirements_is_already_exist:
      'Вы уже подали претензию, по которой сформированы максимальные требования',
    legal_action_claim_not_found:
      'Ожидайте, еще не истекли 10 рабочих дней с момента подачи претензии',
    legal_action_requirements_are_satisfied:
      'Формирование искового заявления недоступно, так как погашены требования претензии, на основании которой формируется исковое заявление',
    phone_msi_verification_phone_do_not_match:
      'Номер телефона не совпадает с указанным в системе МСИ',
    email_msi_verification_email_do_not_match:
      'Электронная почта не совпадает с указанным в системе МСИ',
    msi_verification_email_do_not_match:
      'Номер телефона не совпадает с указанным в системе МСИ',
    msi_verification_phone_do_not_match: 'Телефон не совпадает',
    otp_not_confirmed: 'СМС код не подтвержден',
    msi_verification_request_do_not_match: 'Запрос верификации не совпадает',
    sign_up_disabled: 'Регистрация новых пользователей временно недоступна. Приносим извинения',
    legal_action_is_not_actual:
      'Сумма долга по текущей сделке изменилась и Вам необходимо подать новое исковое заявление',
    email_invalid: 'Некорректный адрес электронной почты',
    email_duplicated:
      'Данный e-mail уже используется другим пользователем. Введите пожалуйста другой e-mail.',
    work_info_employment_area_must_be_indicated:
      'Необходимо указать сферу деятельности',
    work_info_position_must_be_indicated: 'Необходимо указать должность',
    work_info_employment_area_must_be_not_indicated:
      'Сфера деятельности для данного вида занятости не должна быть указана',
    work_info_position_must_be_not_indicated:
      'Должность для данного вида занятости не должна быть указана',
    biometry_required: 'Редактирование профиля Вам будет доступно после успешной верификации. Пройти верификацию Вы можете в профиле личного кабинета',
    files_required: 'Прикрепите файлы',
    car_attachments_max_count_invalid: 'Превышено количество файлов технического паспорта',
    real_estate_attachments_max_count_invalid: 'Превышено количество файлов недвижимости',
    car_attachments_min_count_invalid: 'Прекрипите минимум 2 файла технического паспорта',
    real_estate_attachments_min_count_invalid: 'Прикрепите файлы недвижимости',
    wrong_state_exception: 'К сожалению заявка снята с публикации либо по заявке уже заключен договор займа. Вы можете выбрать в списке другое предложение с подходящими для вас условиями.',
    msi_data_actualization_failed: 'Ошибка при актуализации ваших персональных данных.\nИдентификационные номера не совпадают. Пожалуйста, убедитесь, что вы вводите корректный идентификационный номер. Обратитесь в службу поддержки, если нужна помощь.',
    max_loan_interest_rate: 'Превышено максимальное значение годовой ставки',
    biometry_attempt_denied: 'Невозможно пройти биометрию в заблокированном статусе',
    insufficient_funds_on_the_card: 'Вам необходимо пополнить баланс банковской карты (из расчета суммы по совокупности опубликованных заявок и количества указанных вами займов) перед тем, как опубликовать заявку на главном экране.',
    insufficient_funds_on_the_card_while_accepting: 'Вам необходимо  пополнить баланс банковской карты, если вы хотите заключить договор с заемщиком',
    insufficient_funds_on_the_lender_card: 'Договор займа не может быть подписан по выбранной заявке. Пожалуйста выберите другую заявку на главном экране',
    card_cannot_be_default_with_not_enough_balance: 'Для того, чтобы сделать карту основной, пожалуйста, подтвердите наличие средств на банковской карте',
    user_does_not_allow_to_signin: 'С Вами расторгается Договор возмездного оказания услуг. Ваш аккаунт (личный кабинет) заблокирован.',
    trade_sign_time_limit_violated: 'К сожалению время подписания договора займа по данной заявке истекло.',
    loan_request_accept_time_limit_violated: 'Вы сможете откликнуться на заявку в 00:01 следующего дня.',
    percent_amount_exceeds_the_limit: 'Что-то пошло не так. Повторите попытку создания заявки',
    message_max_length: 'Превышено допустимое количество символов',
    too_many_attempts_to_update_personal_info: 'Вы израсходовали все попытки повторной отправки, попробуйте позже',
    the_only_card_cannot_be_deleted_because_you_have_active_loan_requests: 'Удаление карты недоступно, у вас есть активные заявки на займ',
    lender_card_not_be_paid: 'В связи с техническими обновлениями сервиса необходимо добавить (перепривязать) банковскую карту в вашем профиле, после чего Вам будут доступны новые займы',
    lender_has_no_be_paid: 'Операция недоступна по техническим причинам',
    borrower_card_not_be_paid: 'В связи с техническими обновлениями сервиса необходимо добавить (перепривязать) банковскую карту в вашем профиле, после чего Вам будут доступны новые займы',
    borrower_has_no_be_paid: 'Операция недоступна по техническим причинам',
    can_not_pay_to_lender_from_intervale: 'Приносим извинения, к сожалению оплата данного займа сейчас невозможна по техническим причинам. Попробуйте позже.',
    trade_can_not_pay_to_lender_from_intervale: 'Приносим извинения, к сожалению оплата данного займа сейчас невозможна по техническим причинам. Попробуйте позже.',
    borrower_on_intervale: 'В связи с техническими обновлениями сервиса необходимо погасить займ и вам будет доступно добавить (перепривязать) банковскую карту в вашем профиле и взять новые займы'
  },
  password: {
    note: 'Пароль должен состоять минимум из 1 цифры,\n1 латинского символа в нижнем регистре и 1 в верхнем регистре,\n1 специальный символ: @$!%*?&,\nдлина не менее 8 символов'
  },
  table: {
    empty: 'Нет данных'
  },
  ok: 'ОК',
  calculator: {
    title: 'Вы можете расчитать Ваш доход на калькуляторе:'
  },
  borrower: {
    request: {
      amount: 'Сумма займа',
      period_days: 'Срок',
      percent_year: '% годовых',
      processed_at: 'Дата обработки',
      status: 'Статус',
      outcome: 'К возврату',

      statusValue: {
        active: 'Активная',
        processed: 'Обработано',
        canceled: 'Отменена',
        draft: 'Неактивная'
      }
    }
  },
  loan: {
    type: {
      [LoanRequestType.LONG]: 'Обычный',
      [LoanRequestType.SHORT]: 'Краткосрочный'
    },
    lending: {
      transactions: 'Поступление средств',
      repayment: 'Погашение'
    }
  },
  signUp: {
    address: {
      country: 'Страна',
      region: 'Область',
      city_type: 'Тип населенного пункта',
      city: 'Населенный пункт',
      street_type: 'Тип локации',
      street: 'Название локации',
      house: 'Дом',
      apartment: 'Квартира',
      house_unit: 'Корпус'
    },
    wrong_doc_type:
      'Регистрация в статусе Заемщик доступна только для граждан РБ',
    wrong_dob:
      'Регистрация в приложении доступна только совершеннолетним пользователям.'
  },
  button: {
    save: 'Сохранить',
    pay: 'Оплатить',
    claim: 'Создать Претензию',
    legal_action: 'Подать исковое заявление в Третейский суд',
    confirm: 'Подтвердить',
    sign: 'Подписать',
    send_otp: 'Получить код',
    sign_agreement: 'Подписать согласие',
    'sign-claim': 'Подписать претензию',
    'sign-legal-action': 'Подписать исковое заявление',
    claim_info: 'Практические советы'
  },
  scoring_agreement: {
    success_message:
      'Пожалуйста, подождите еще немного! Активация аккаунта может занять до 3-х рабочих дней, о чем вам придет сообщение на емайл-адрес'
  },
  profile: {
    confirm_extra_info_changes: 'Подтверждаю актуальность данных',
    change: 'Редактировать профиль',
    change_contact_info: 'Редактировать контактную информацию',
    consent_management: 'Персональные данные',
    consent: {
      title: 'Согласие на обработку песональных данных',
      accepted_date: 'Дано согласие: ',
      revoked_date: 'Отозвано: ',
      accept: 'Принять',
      revoke: 'Отозвать',
      initial_message: 'Вы не давали согласие на обработку персональных данных',
      initial_accept: 'Дать согласие на обработку персональных данных',
      download: 'Просмотр согласия на обработку песональных данных'
    },
    phone: 'Телефон',
    email: 'Электронная почта',
    codeword: 'Кодовое слово',
    work_place: {
      title: 'Информация о месте работы',
      employment_type: 'Вид занятости',
      employment_area: 'Сфера деятельности',
      company_name: 'Наименование организации',
      position: 'Должность',
      experience: 'Стаж работы в данной организации (мес.)'
    },
    extra_info: {
      title: 'Дополнительная информация',
      education: 'Образование',
      family_status: 'Семейное положение',
      driving_license: 'Водительское удостоверение',
      car_owned: 'Автомобиль в собственности',
      real_estate_owned: 'Недвижимость в собственности',
      number_of_dependents: 'Количество иждивенцев',
      need_to_refund_expenses:
        'Обязанность возмещать расходы, затраченные государством на содержание детей (в детских домах, интернатах, спец.учреждениях и пр.)',
      monthly_income: 'Общий ежемесячный доход в BYN'
    },
    living_address: {
      title: 'Адрес проживания',
      country_code: 'Код страны',
      region: 'Область',
      locality_name: 'Насленный пункт',
      street_name: 'Улица',
      house_number: 'Дом',
      apartment_number: 'Квартира'
    },
    actions: {
      title: 'Для полного доступа к фунциям "Капуста - онлайн-заимствования" Вам необходимо завершить несколько простых шагов',
      biometry: {
        title: 'Пройти верификацию',
        description: 'Для активации вашего профиля необходимо пройти верификацию в МСИ с использованием биометрических данных',
        action_name: 'Пройти верификацию'
      },
      credit_register: {
        title: 'Предоставление согласия',
        description: 'Для выставления рейтинга (скоринговой оценки) необходимо дать согласие на предоставление кредитного отчета из Кредитного регистра',
        action_name: 'Предоставить согласие'
      },
      add_card: {
        title: 'Добавление банковской карты',
        description: 'Для пополнения вашего счета добавьте банковскую карту, на которую вы хотите получать денежные средства',
        action_name: 'Добавить карту'
      },
      verify_email: {
        title: 'Подтвердить электронную почту',
        description: 'Для получения актуальной информации от сервиса пройдите подтверждение e-mail',
        action_name: 'Подтвердить почту'
      },
      payment_migration: {
        title: 'Обновите данные карты',
        description: 'В связи с техническими обновлениями сервиса необходимо добавить (перепривязать) банковскую карту в вашем профиле с {dateTime} г. При успешном обновлении банковской карты ваши опубликованные заявки будут доступны для пользователей на сайте'
      }
    }
  },

  trade: {
    btn: {
      sign: 'Подписать'
    }
  },
  claim: {
    request: {
      title: 'Подтвердите создание претензии',
      message:
        'Для подтверждения претензии введите СМС код отправленный на Ваш телефон',
      code_label: 'Введите код из СМС',
      code_was_sent: 'СМС отправлен',
      claim_created: 'Претензия сформирована'
    }
  },
  legal_action: {
    request: {
      title: 'Подтвердите подачу искового заявления',
      message:
        'Для подтверждения подачи искового заявления введите СМС код отправленный на Ваш телефон',
      code_label: 'Введите код из СМС',
      code_was_sent: 'СМС отправлен',
      legal_action_created: 'Исковое заявление сформировано'
    }
  },
  message: {
    newsPage: {
      title: 'Новости'
    },
    documentsPage: {
      title: 'Документы',
      documents: {
        offer: 'Оферта ОАО Паритетбанк',
        rules: 'Правила работы Сервиса',
        scoring: 'Положение о скоринге',
        serviceContract: 'Договор возмездного оказания услуг',
        personalDataProcessing: 'Политика обработки персональных данных',
        personalDataAgreement: 'Согласие на обработку персональных данных',
        contractTemplate: 'Договор займа (образец)'
      }
    },
    confirmEmailResultPage: {
      success: {
        title: 'Электронная почта подтверждена!',
        description: 'Подтверждение электронной почты завершено успешно!',
        button_text: 'Перейти в профиль'
      },
      failed: {
        title: 'Ссылка устарела',
        description: 'К сожалению, эта ссылка для подтверждения электронной почты больше неактивна. Пожалуйста, запросите новую ссылку для подтверждения.',
        button_text: 'Перейти в профиль'
      }
    },
    messagesPage: {
      message: 'Сообщение',
      agreement: 'Номер договора',
      placeholder: 'Написать сообщение',
      btn: 'Отправить',
      tostMsg: 'Сообщение успешно отправлено',
      agreementDate: 'Дата договора'
    },
    settingsPage: {
      btn: 'Изменить пароль'
    },
    loanPage: {
      back: 'Назад',
      title: 'Номер договора',
      notice: '* - без учёта комиссий',
      items: {
        date: 'Дата транзакции',
        amount: 'Сумма транзакции*',
        due_main_depth: 'В т.ч. ОД',
        due_interest_depth: 'В т.ч. %',
        penalty_amount: 'В т.ч. пени',
        status: 'Статус',
        statusValue: {
          [TransactionStatus.PENDING]: 'В процессе',
          [TransactionStatus.PROCESSED]: 'Успешно',
          [TransactionStatus.FAILED]: 'Ошибка'
        }
      }
    },
    changePasswordPage: {
      btnSave: 'Сохранить',
      btnCancel: 'Отмена',
      oldPassword: {
        label: 'Старый пароль'
      },
      newPassword: {
        label: 'Новый пароль'
      },
      confirmPassword: {
        label: 'Подтверждение нового пароля'
      },
      toastMsg: 'Пароль успешно изменен'
    },
    arbitrationPage: {
      title: 'Третейский суд',
      desc: 'Свидетельство № 20 выдано Главным Управлением Юстиции Мингорисполкома',
      license: {
        begin:
          'Главное управление юстиции Мингорисполкома 02.11.2022 поставило на учет и внесло в ',
        link: 'реестр',
        end: ' третейских судов Республики Беларусь постоянно действующий третейский суд Общества с ограниченной ответственностью «Инновационные Финансовые Стратегии». Председателем третейского суда избран Берёзкин Вадим Викторович.'
      },
      contact: {
        title: 'Реквизиты / Контакты',
        items: {
          first: {
            title: 'Реквизиты для оплаты третейского сбора:',
            data: [
              'ООО "ИФС" (УНП 193601287)',
              'ОКПО 505592505000',
              'Р/с: BY18 POIS 3012 0139 1603 0193 3002 в ОАО "Паритетбанк"',
              'BIC: POISBY2X',
              'Назначение платежа: Третейский сбор по иску (Ф.И.О. истца) к (Ф.И.О. ответчика)'
            ],
            documentFirst: 'Тарифы суда',
            documentSecond: 'Регламент',
            documentThird: 'Уведомление об изменении местонахождения'
          },
          second: {
            title: 'Место нахождения:',
            data: '220030, г. Минск, ул. Мясникова, д. 27, пом. 26'
          },
          third: {
            title: 'Телефон',
            data: '+375 (33) 696 09 09'
          },
          fourth: {
            title: 'Email:',
            data: 'court@ifs.by'
          }
        }
      },
      purpose: {
        title: 'Что такое третейский суд:',
        items: [
          'Третейский суд — организация, не входящая в судебную систему Республики Беларусь, создаваемая для разрешения споров, образуемого по соглашению сторон для разрешения конкретного спора.',
          'Третейское соглашение — это двусторонняя сделка, которая порождает у её сторон обязанность в случае спора обратиться в указанный в соглашении третейский суд.',
          'Решение третейского суда вступает в законную силу в день его принятия и подлежит обязательному исполнению в соответствии с законодательством Республики Беларусь. Решение третейского суда, не исполненное добровольно в установленный срок, подлежит принудительному исполнению в порядке, установленном законодательством об исполнительном производстве.',
          'Третейское соглашение не ограничивает истца в праве на обращение в государственный суд. Однако если ответчик не позднее своего первого заявления по существу спора заявит ходатайство о его передаче на разрешение третейского суда, это станет основанием оставить исковое заявление без рассмотрения.'
        ]
      },
      advantages: {
        title: 'Преимущества третейского суда:',
        items: [
          'простая и быстрая процедура',
          'решения третейского суда окончательны и не подлежат обжалованию',
          'разбирательства проходит по месту нахождения третейского суда в г. Минске, а не по месту нахождения ответчика',
          'решения третейского суда исполняются судебными исполнителями принудительно, на основании полученного в суде общей юрисдикции исполнительного листа'
        ]
      },
      advices: {
        title: 'Практические советы:',
        items: {
          first:
            'При невозврате денежных средств в установленный срок у Займодавца возникает право на обращение в суд. Следует учитывать, что в соответствии с условиями договора займа и третейской оговоркой предусмотрен обязательный претензионный порядок и определён суд для рассмотрения споров — постоянно действующий третейский суд Общества с ограниченной ответственностью «Инновационные Финансовые Стратегии».',
          second: {
            begin:
              'Воспользовавшись инструментами Сервиса можно сформировать Претензию, которая будет направлена Заёмщику (должнику). ',
            bold: 'Обращаем внимание на то, что максимальный размер начисляемых пеней по займу составляет 50% от суммы основного долга. Рекомендуем дождаться предусмотренного начисления и затем сформировать Претензию.',
            end: ' Если не дожидаться начисления максимального размера пеней, предусмотренного договором займа, то и подать иск можно на тот размер требований, которые были определены фактической Претензией на дату её составления. В течении 10 дней с момента направления претензии Заёмщиком (должником) должен быть дан ответ Займодавцу на претензию и/или погашены требования.'
          },
          third:
            'При отсутствии ответа на Претензию и/или отсутствии погашения требований и истечении 10 дневного срока ответа на Претензию Займодавец имеет право обратиться в суд, воспользовавшись инструментами Сервиса по составлению и отправке Искового заявления в третейский суд Общества с ограниченной ответственностью «Инновационные Финансовые Стратегии».',
          fourth: {
            beginBold:
              'В момент подачи Искового заявления должен быть оплачен по ',
            firstLink: 'реквизитам третейского суда',
            middleBold: ' в соответствии с ',
            secondLink: 'тарифами третейского суда',
            endBold:
              ' третейский сбор за рассмотрение искового заявления (как правило — 2 БВ) и прикреплён скрин (фото) чека оплаты третейского сбора.'
          }
        }
      }
    },
    aboutPage: {
      title: 'О нас',
      desc: {
        title: 'Капуста — онлайн-заимствования',
        items: {
          first:
            'ООО «ИФС» (Оператор сервиса онлайн-заимствования) оказывает платные услуги по созданию условий для заключения и исполнения посредством сервиса онлайн-заимствования договоров займа денежных средств и договоров, заключенных в обеспечение исполнения обязательств по договору займа денежных средств в соответствии с утвержденными Правилами сервиса потребительского онлайн-заимствования «Капуста — онлайн-заимствования», условиями договора возмездного оказания услуг и тарифами сервиса.',
          second: {
            begin:
              '«Капуста — онлайн-заимствования» — это автоматизированный сервис онлайн-заимствования, который начал свою работу в ',
            important: 'мае 2023 года.',
            end: ' Сегодня сервис доступен для заёмщиков и займодавцев по всей Беларуси.'
          },
          third: {
            begin: 'В настоящий момент ',
            bold: '«Капуста — онлайн-заимствования» представлен сервисом потребительского онлайн-заимствования.',
            middle:
              ' Таким образом участниками данного сервиса в соответствии с Правилами работы данного сервиса могут быть только физические лица (подробнее — ',
            link: 'см.Правила',
            end: ').'
          },
          fourth:
            'Предоставление денежных средств в займ посредством сервиса онлайн-заимствования и заключение договоров в обеспечение исполнения обязательств по договору займа денежных средств носят рисковый характер.',
          fifth:
            'Оператор сервиса онлайн-заимствования не является стороной по заключаемым посредством этого сервиса онлайн-заимствования договору займа денежных средств и договору, заключенному в обеспечение исполнения обязательств по договору займа денежных средств.',
          sixth:
            'Оператор сервиса онлайн-заимствования также не вправе привлекать на свои банковские счета и аккумулировать денежные средства клиентов для их последующего направления заемщикам, иным образом участвовать в движении денежных средств при осуществлении расчетов по договорам займа денежных средств.'
        }
      },
      values: {
        title: 'Наши ценности',
        data: 'Миссия ООО «ИФС» — повышение доступности финансовых услуг и защиты прав их потребителей, а также формирования условий для дальнейшего развития предпринимательства и деловой инициативы.'
      },
      different: {
        title: 'Что отличает нас от других?',
        items: [
          {
            count: 'first',
            title: 'Экспертиза',
            data: 'Мы успешно представляем услуги с 2023 года, используя лучший опыт и методики финансового рынка, привнося и откликаясь современным условиям.'
          },
          {
            count: 'second',
            title: 'Роботизация',
            data: 'Мы используем в т.ч. автоматизированные технологии при работе с клиентами.'
          },
          {
            count: 'third',
            title: 'P2P платформа',
            data: 'У нас есть собственная автоматизированная платформа «Капуста — онлайн-заимствования» в Республике Беларусь.'
          },
          {
            count: 'fourth',
            title: 'Устойчивый бизнес',
            data: 'Мы всегда стремимся развиваться: улучшать существующие процессы и создавать новые продукты и услуги.'
          },
          {
            count: 'fifth',
            title: 'Экология',
            data: 'Мы стремимся минимизировать воздействие нашей деятельности на окружающую среду. Мы регулируем потребление энергии в офисах, предотвращая чрезмерное использование энергии. Большая часть нашего бизнеса безбумажна, большинство документации хранится в цифровом виде в облачных хранилищах. Мы поддерживаем практику в т.ч. удалённой работы наших сотрудников, чтобы сократить выбросы углекислого газа при поездке на работу и домой.'
          },
          {
            count: 'sixth',
            title: 'Наши люди',
            data: 'Своим успехом мы обязаны нашим работникам. Сотрудники свободно могут пользоваться личным рабочим пространством и предоставленными девайсами, а также зоной отдыха. Наши сотрудники активно проходят различные курсы в целях повышения квалификации. В целях поощрения успешной работы предусмотрены различные бонусы, премии и командировки от имени компании. '
          },
          {
            count: 'seventh',
            title: 'Наши клиенты',
            data: 'Мы стремимся создать удобную финансовую экосистему, чтобы обеспечить реальные финансовые возможности.'
          },
          {
            count: 'eleventh',
            title: 'Управление',
            data: 'Безопасность и прозрачность деятельности является одними из ключевых аспектов нашей работы. Наша компания придерживается установленной политики, касающейся противодействия коррупции, мошенничеству, отмыванию денег, а также по сохранению и конфиденциальности данных. Ежеквартально мы формируем отчётность о деятельности сервиса онлайн-заимствования в Национальный банк Республики Беларусь, а также ежегодно публикуем необходимые финансовые отчёты.'
          }
        ]
      },
      contact: {
        title: 'Раскрытие информации',
        items: {
          first: {
            title: 'Полное наименование организации:',
            data: 'Общество с ограниченной ответственностью «Инновационные Финансовые Стратегии» Сокращенное наименование организации: ООО «ИФС»'
          },
          second: {
            title: 'Дата и номер государственной регистрации:',
            data: 'Общество с ограниченной ответственностью «Инновационные Финансовые Стратегии» зарегистрировано Минским горисполкомом 18 ноября 2021 г. №193601287',
            documentFirst: 'Свидетельство о гос. регистрации',
            middleDataBegin: 'Свидетельство № 1 о включении в ',
            link: 'реестр',
            middleDataEnd:
              ' Операторов сервисов онлайн-заимствования, выдано Национальным банком Республики Беларусь 14.01.2022. Таким образом Обществу с ограниченной ответственностью «Инновационные Финансовые Стратегии» присвоен статус Оператора сервиса онлайн-заимствования.',
            documentSecond: 'Свидетельство о включении в реестр'
          },
          third: {
            title: 'Учредителями Общества являются:',
            items: {
              first: '— Овчаров Роман Юрьевич, Республика Беларусь;',
              second:
                '— Общество с ограниченной ответственностью Инвестиционная Компания «ПИФАГОР» (Российская Федерация)',
              third:
                '— Общество с ограниченной ответственностью «ОЛИМП Групп» (Российская Федерация)',
              fourth:
                'Бенефициарные владельцы ООО ИК «ПИФАГОР»: Sabr Limited (Seychelles)',
              five: 'Бенефициарный владелец Sabr Limited: Тарханова Ульяна Анатольевна (Российская Федерация)',
              six: 'Бенефициарный владелец ООО «ОЛИМП Групп»: Беляков Александр Алексеевич (Российская Федерация)'
            },
            documentFirst: 'Устав',
            documentSecond: 'Уведомление о смене местонахождения',
            documentThird: 'Годовая бухгалтерская отчётность {year}'
          },
          fourth: {
            title: 'Адреса и реквизиты:',
            items: {
              first: '220030, г. Минск, ул. Мясникова, д. 27, пом. 31, пом. 26',
              second: {
                text: 'e-mail: ',
                link: 'info@kapysta.by'
              },
              third: 'Тел: +375 (25) 908-08-08 (Viber, Telegram, WhatsApp)',
              fourth: '+375 (25) 609-09-09'
            }
          },
          fifth: {
            title: 'Банковские реквизиты:',
            items: {
              first: 'Р/с: BY95 MMBN 3012 0000 0101 0208 4970',
              second: 'в ОАО «Банк Дабрабыт»',
              third: '220002, г.Минск, ул. Коммунистическая, д.49, пом.1',
              fourth: 'BIC MMBNBY22',
              fifth: 'УНП: 193601287',
              sixth: 'ОКПО 505592505000'
            }
          }
        }
      }
    },
    questionsPage: {
      title: 'Частые вопросы',
      items: {
        first: {
          title: 'Как получить деньги (займ)?',
          text: {
            first: {
              begin: 'Для получения займа необходимо ',
              link: 'Зарегистрироваться',
              end: ' на нашем сайте и иметь платёжную карточку любого белорусского банка в белорусских рублях, поддерживающую сервис р2р-переводов.'
            },
            second:
              'В течении 3 рабочих дней с момента регистрации и предоставления согласия на проверку кредитной истории вам, как Заёмщику будет присвоена рейтинговая оценка на основании ваших данных, после чего вы сможете совершать сделки займа на нашем сервисе.',
            third: {
              begin:
                'Все активные предложения по займам размещены на главной странице сайта. Можно воспользоваться предложениями ',
              link: '«Они готовы выдать деньги прямо сейчас»',
              end: ' и выбрать те условия займа, которые являются приемлемыми для вас.'
            },
            fourth:
              'Перед перечислением денежных средств вам будет предложено ознакомиться с условиями и подписать договор займа. В момент зачисления вам денежных средств будет отправлено сообщение на указанный вами емайл.',
            fifth: {
              begin:
                'После получения займа внимательно отнеситесь к своевременному погашению займа, т.к. по каждой сделке займа ',
              bold: 'подаётся информация в Кредитный регистр Национального банка Республики Беларусь и ваша платёжная дисциплина будет влиять на вашу кредитную историю.',
              middle:
                ' Кредитный отчет (кредитную историю) в виде документа на бумажном носителе можно получить в Управлении «Кредитный регистр» Национального банка Республики Беларусь, а также в виде электронного документа посредством ',
              link: 'веб-портала Кредитного регистра',
              end: ' в глобальной компьютерной сети Интернет.'
            },
            sisxth:
              'Все сведения о заключённых сделках займа будут отражаться в вашем «Профиле» на сайте. Там же следует производить оплаты, связанные с погашением займа.'
          }
        },
        second: {
          title: 'Как инвестировать (выдать займ)?',
          text: {
            first: {
              begin:
                'Для возможности инвестировать (совершать сделки займа) необходимо ',
              link: 'Зарегистрироваться',
              end: ' на нашем сайте и иметь платежную карточку любого белорусского банка в белорусских рублях, поддерживающую сервис р2р-переводов.'
            },
            second:
              'Физические лица могут совершать неограниченное число сделок займов в календарном месяце на сервисе онлайн-заимствования.',
            third: {
              begin:
                'Чтобы создать свое предложение на займ, следует воспользоваться инструментами сайта по созданию заявки в своем «Профиле». Обратите внимание, что выбрав ',
              bold: 'количество займов (публикаций) ваше предложение будет публиковаться исходя из заявленного вами числа раз. Соответственно такое количество займов может быть выдано вами ',
              end: '(в т.ч. автоматически) на аналогичных условиях, если на него откликнутся Заёмщики.'
            },
            fourth:
              'Если вы хотите создать предложение с иными условиями, нужно создать новую заявку (предложение) и указать также количество ее публикаций.',
            fifth: {
              begin:
                'Обратите внимание, что срок размещения на сайте предложения (заявки) может быть ограничен ',
              important: '30-ю днями',
              end: ', после чего оно будет снято с публикации автоматически.'
            },
            sixth: {
              begin:
                'Все активные предложения по займам размещены на главной странице сайта.',
              bold: '«Они готовы взять деньги прямо сейчас»',
              end: ' и выбрать те условия займа, которые являются приемлемыми для вас.'
            },
            new: 'Чтобы выдать займ по заявке, опубликованной Заемщиком, следует воспользоваться инструментами сайта, нажав кнопку «Инвестировать», после чего будет направлен запрос Заемщику для подписания договора займа, затем при успешном завершении данного действия произойдет списание денежных средств с Займодавца.',
            seventh: {
              begin:
                'Фактическим перечислением денежных средств по заявке (оферте) вы присоединяетесь к договору займа, подписанному непосредственно Заемщиком. Ознакомиться с образцом договора займа можно в разделе ',
              bold: '«Документы»',
              end: ' на сайте.'
            },
            eighth:
              'Все сведения о заключенных сделках займа будут отражаться в вашем «Профиле» на сайте. Там же будут отражаться все оплаты, связанные с погашением займа.',
            nineth: {
              begin:
                'В случае наступления просрочки на неоплаченную часть будут начисляться пени исходя из двукратной ставки процентов по займу, но не более 50% от суммы основного долга. При длительных просрочках (свыше 30-90 дней) рекомендуем обратиться за взысканием (подробнее – см. ',
              bold: 'Третейский суд',
              end: ').'
            }
          }
        },
        third: {
          title: 'Что обозначает рейтинг (скоринговая оценка) Заемщика?',
          text: {
            first: {
              begin: 'Порядок, критерии и методика скоринга регулируются ',
              link: 'Положением о порядке проведения оценки платежеспособности физических лиц – клиентов сервиса потребительского онлайн-заимствования «Капуста – онлайн-заимствования», присвоении скоринговой оценки.'
            },
            second: {
              begin:
                'Скоринговая оценка – результат комплексной финансовой оценки способности Заемщика надлежащим образом исполнить свои обязательства, осуществляемой Оператором на основании анкетных данных Заемщика и анализа ретроспективных данных: информации из Кредитного регистра (КР), информации из ОПИ, истории заимствований на Сервисе. Скоринговая оценка определяется по шкале от 0 до 100 баллов, ',
              bold: 'где 100 – наивысшая оценка вероятности Заемщика надлежащим образом исполнить свои обязательства.'
            },
            third: {
              begin: 'Наличие у Заемщика скоринговой оценки ',
              bold: 'от 1 до 20 баллов ',
              end: '– с высокой вероятностью говорит о наличии текущих просрочек в банках и иных учреждениях свыше 90 дней и (или) открытых исполнительных производствах в ОПИ (о принудительном взыскании долга), указывающей на данные обстоятельства кредитной истории в КР, низкого уровня дохода, рабочей специальности, среднего и (или) средне-специального образования Заемщика, отсутствии в собственности недвижимости и (или) автомобиля и наличии иждивенцев и (или) обязанности возмещать расходы, затраченные государством на содержание детей.'
            },
            fourth: {
              begin: 'Наличие у Заемщика скоринговой оценки ',
              bold: 'от 21 до 40 баллов ',
              end: '– с высокой вероятностью говорит о наличии текущих просрочек в банках и иных учреждениях от 30 до 90 дней и (или) открытых исполнительных производствах в ОПИ (о принудительном взыскании долга), указывающей на данные обстоятельства кредитной истории в КР и (или) возрасте Заемщика до 30 лет, низкого уровня дохода, среднего и (или) средне-специального образовании Заемщика, рабочей специальности, отсутствии в собственности недвижимости и (или) наличии иждивенцев.'
            },
            fifth: {
              begin: 'Наличие у Заемщика скоринговой оценки ',
              bold: 'от 41 до 69 баллов ',
              end: '– с высокой вероятностью говорит о наличии текущей просрочки до 7 дней и (или) в прошлом факта допущения просрочек в банках и иных учреждениях до 30 дней, указывающей на данные обстоятельства кредитной истории в КР, среднего уровня дохода, вероятном наличии водительских прав и (или) автомобиля в собственности.'
            },
            sixth: {
              begin: 'Наличие у Заемщика скоринговой оценки ',
              bold: 'от 70 до 100 баллов ',
              end: '– с высокой вероятностью говорит об удовлетворительной кредитной истории в КР, заявленном уровне дохода свыше 1800 бел.рубл., возрасте Заемщика от 31-50 лет, высшем образовании, наличии водительских прав и (или) автомобиля и (или) недвижимости в собственности и (или) успешно завершенных 2 и более сделках займов на Сервисе (при их наличии без допущения просрочек).'
            }
          }
        },
        fourth: {
          title: 'Предупреждение о конфиденциальности данных'
        }
      }
    },
    investingPage: {
      heading: {
        title: 'Капуста — лучшее решение!',
        firstDesc:
          'Используя наш сервис вы получаете альтернативную возможность инвестирования. Теперь заключение сделок займов возможно онлайн, 24/7, на условиях, которые вы выбираете сами'
      },
      advantages: {
        title: 'Почему все выбирают Капусту:',
        items: {
          first: 'Выдача и погашение займа круглосуточно',
          second: 'Вы сами выбираете условия, сумму и срок займа',
          third: 'За просроченный платёж в вашу пользу начисляются пени',
          fourth: 'SSL сертификат, защита личной информации',
          fifth: 'Справочно-информационная поддержка',
          sixth: 'Взыскание в третейском суде'
        }
      },
      hiw: {
        title: 'Три простых шага для получения денег:',
        first: {
          title: 'Регистрация',
          desc: 'через МСИ займёт не более 5 минут.  Вы становитесь полноценным участником Сервиса и владельцем личного "карманного" Банка'
        },
        second: {
          title: 'Выдача займа',
          desc: 'для выдачи займа создайте свои уникальные предложения по займам, воспользовавшись инструментами Сервиса или остановите свой выбор на предложениях Заемщиков'
        },
        third: {
          title: 'Подпись договора',
          desc: 'договор займа является реальным договором. Перечислением денежных средств вы присоединяетесь к условиям договора'
        }
      },
      memo: {
        title: 'Памятка для Займодавцев',
        items: {
          first: {
            begin:
              'Для возможности инвестировать (совершать сделки займа) необходимо ',
            link: 'Зарегистрироваться',
            end: ' на нашем сайте и иметь платежную карточку любого белорусского банка в белорусских рублях, поддерживающую сервис р2р-переводов.'
          },
          second:
            'Физические лица могут совершать неограниченное число сделок займов в календарном месяце на сервисе онлайн-заимствования.',
          third: {
            begin:
              'Чтобы создать свое предложение на займ, следует воспользоваться инструментами сайта по созданию заявки в своем «Профиле». Обратите внимание, что выбрав ',
            bold: 'количество займов (публикаций) ваше предложение будет публиковаться исходя из заявленного вами числа раз. Соответственно такое количество займов может быть выдано вами',
            end: ' (в т.ч. автоматически) на аналогичных условиях, если на него откликнутся Заёмщики.'
          },
          fourth:
            'Если вы хотите создать предложение с иными условиями, нужно создать новую заявку (предложение) и указать также количество ее публикаций.',
          fifth: {
            begin:
              'Обратите внимание, что срок размещения на сайте предложения (заявки) может быть ограничен ',
            important: '30-ю днями',
            end: ', после чего оно будет снято с публикации автоматически.'
          },
          sixth: {
            begin:
              'Все активные предложения по займам размещены на главной странице сайта.',
            beginImportant: ' Также можно воспользоваться заявками Заемщиков ',
            link: '«Они готовы взять деньги прямо сейчас»',
            endImportant:
              ' и выбрать те условия займа, которые являются приемлемыми для вас.'
          },
          new: 'Чтобы выдать займ по заявке, опубликованной Заемщиком, следует воспользоваться инструментами сайта, нажав кнопку «Инвестировать», после чего будет направлен запрос Заемщику для подписания договора займа, затем при успешном завершении данного действия произойдет списание денежных средств с Займодавца.',
          seventh: {
            begin:
              'Фактическим перечислением денежных средств по заявке (оферте) вы присоединяетесь к договору займа, подписанному непосредственно Заемщиком. Ознакомиться с образцом договора займа можно в разделе ',
            link: '«Документы»',
            end: ' на сайте.'
          },
          eighth:
            'Все сведения о заключенных сделках займа будут отражаться в вашем «Профиле» на сайте. Там же будут отражаться все оплаты, связанные с погашением займа.',
          ninth: {
            begin:
              'В случае наступления просрочки на неоплаченную часть будут начисляться пени исходя из двукратной ставки процентов по займу, но не более 50% от суммы основного долга. При длительных просрочках (свыше 30-90 дней) рекомендуем обратиться за взысканием (подробнее – см. ',
            link: 'Третейский суд',
            end: ').'
          }
        },
        note: {
          begin:
            'Справочно: Кредитный отчет (кредитную историю) в виде документа на бумажном носителе можно получить в Управлении «Кредитный регистр» Национального банка Республики Беларусь, а также в виде электронного документа посредством ',
          link: 'веб-портала Кредитного регистра',
          end: ' в глобальной компьютерной сети Интернет.'
        }
      },
      signUp: {
        title: 'Зарегистрируйтесь прямо сейчас!',
        btn: 'Зарегистрироваться'
      }
    },
    getLoanPage: {
      heading: {
        title: 'Капуста — лучшее решение!',
        firstDesc:
          'Самый быстрый и удобный выход из ситуации, когда срочно нужны деньги — оформить займ в Капусте.',
        secondDesc:
          'Получение Займа доступно с любой кредитной историей. В Капусте легко могут получить деньги даже те, кому отказали в банке, а также студенты, пенсионеры и временно безработные. Вам не потребуются справки, страховка и залог. Займ предоставляется на любую карту и без визитов в отделение банка. От вас потребуется только наличие компьютера или мобильного телефона.'
      },
      advantages: {
        title: 'Почему все выбирают Капусту:',
        items: {
          first: 'БЕЗ: справок, залогов, страховок и поручителей',
          second: 'Круглосуточно: потребуется только мобильный телефон',
          third: 'Вы сами выбираете условия, сумму и срок займа',
          fourth: 'Вы платите проценты только за дни пользования займом',
          fifth: 'SSL сертификат, защита личной информации',
          sixth: 'Возможность улучшить свой  рейтинг в Капусте'
        }
      },
      hiw: {
        title: 'Три простых шага для получения денег:',
        first: {
          title: 'Регистрация',
          desc: 'через МСИ займёт не более 5 минут.  Вы становитесь полноценным участником Сервиса и владельцем личного "карманного" Банка'
        },
        second: {
          title: 'Получение займа',
          desc: 'все сделки займов заключаются напрямую между физическими лицами. Для получения денег создайте свое уникальное предложение на займ или воспользуйтесь предложениями от Займодавцев и сделайте свой выбор'
        },
        third: {
          title: 'Подпись договора',
          desc: 'осуществляется с помощью аналога собственноручной подписи (просто введите код из SMS- или PUSH-уведомления)'
        }
      },
      memo: {
        title: 'Памятка для Заёмщиков',
        items: {
          first: {
            begin: 'Для получения займа необходимо ',
            link: 'Зарегистрироваться',
            end: ' на нашем сайте и иметь платёжную карточку любого белорусского банка в белорусских рублях, поддерживающую сервис р2р-переводов.'
          },
          second:
            'В течении 3 рабочих дней с момента регистрации и предоставления согласия на проверку кредитной истории вам, как Заёмщику будет присвоена рейтинговая оценка на основании ваших данных, после чего вы сможете совершать сделки займа на нашем сервисе.',
          third: {
            begin:
              'Все активные предложения по займам размещены на главной странице сайта. Можно создать свое уникальное предложение, воспользовавшись инструментами сайта по созданию заявки в своем «Профиле», которое отобразится на главной странице',
            link: '«Они готовы взять деньги прямо сейчас»',
            link_1: '«Они готовы выдать деньги прямо сейчас»',
            end: ' или воспользоваться предложениями Займодавцев {0} и выбрать те условия займа, которые являются приемлемыми для вас.'
          },
          fourth:
            'Перед перечислением денежных средств вам будет предложено ознакомиться с условиями и подписать договор займа. В момент зачисления вам денежных средств будет отправлено сообщение на указанный вами емайл.',
          fifth: {
            begin:
              'После получения займа внимательно отнеситесь к своевременному погашению займа, т.к. по каждой сделке займа ',
            end: 'подаётся информация в Кредитный регистр Национального банка Республики Беларусь и ваша платёжная дисциплина будет влиять на вашу кредитную историю.'
          },
          sixth:
            'Все сведения о заключённых сделках займа будут отражаться в вашем «Профиле» на сайте. Там же следует производить оплаты, связанные с погашением займа.',
          next: 'Чтобы выдать займ по заявке, опубликованной Заемщиком, следует воспользоваться инструментами сайта, нажав кнопку «Инвестировать», после чего будет направлен запрос Заемщику для подписания договора займа, затем при успешном завершении данного действия произойдет списание денежных средств с Займодавца.'
        },
        note: {
          begin:
            'Справочно: Кредитный отчет (кредитную историю) в виде документа на бумажном носителе можно получить в Управлении «Кредитный регистр» Национального банка Республики Беларусь, а также в виде электронного документа посредством ',
          link: 'веб-портала Кредитного регистра',
          end: ' в глобальной компьютерной сети Интернет.'
        }
      },
      signUp: {
        title: 'Зарегистрируйтесь прямо сейчас!',
        btn: 'Зарегистрироваться'
      }
    },
    legislation: {
      title: 'Законодательство',
      firstRow:
        'Данный раздел не имеет статуса юридической базы. Публикация нормативных правовых актов, ссылок на них носит справочный, информационный характер.',
      secondRow: {
        firstText:
          'Для ознакомления с нормативными правовыми актами можно перейти по ссылке:',
        link: 'Законодательство о сервисах онлайн-заимствования',
        secondText: 'Перейдя по ссылке вы покидаете наш сайт'
      }
    },
    contacts: {
      title: 'Контакты',
      address: {
        title: 'Адрес местонахождения:',
        data: '220030, г. Минск, ул. Мясникова, д.27, пом.31, пом.26'
      },
      postalAddress: {
        title: 'Почтовый адрес :',
        data: '220030, г. Минск, ул. Мясникова, д.27, пом.31'
      },
      time: {
        title: 'Время работы офиса:',
        week: 'Понедельник - Пятница',
        data: 'с 09:00 до 18:00'
      },
      phones: {
        title: 'Телефоны:',
        first: '+375 (25) 908-08-08 (Viber, Telegram, WhatsApp)',
        second: '+375 (25) 609-09-09'
      },
      support: {
        title: 'Служба поддержки:',
        data: 'support@kapysta.by'
      }
    },
    contract: {
      otp: {
        placeholder: 'Введите код из SMS'
      },
      successModal: {
        title: 'Поздравляем!',
        desc: {
          text: 'Ожидайте перечисления денежных средств на Вашу карту. Сделка отражена в ',
          link: 'Профиле'
        },
        btn: 'OK'
      },
      errorModal: {
        title: 'Внимание!',
        desc: 'Не удалось зачислить денежные  средства. Ранее подписанный вами договор займа аннулируется.',
        btn: 'OK'
      },
      btn: 'Подписать договор'
    },
    bid: {
      title: 'Создание заявки',
      currencyList: {
        BYN: 'BYN',
        USD: 'USD'
      },
      currency: {
        title: 'Выбор валюты'
      },
      totalAmount: {
        title: 'Общая сумма',
        placeholder: '10 000 000'
      },
      minAmount: {
        title: 'Мин. сумма займа',
        placeholder: '100'
      },
      aliquote: {
        title: 'Кратность сумме'
      },
      percent: {
        title: '% годовых',
        placeholder: '12,5%'
      },
      terms: {
        title: 'Срок займа',
        fromPlaceholder: 'c: 22.03.2023',
        toPlaceholder: 'по: 22.03.2023',
        from: 'с',
        to: 'по'
      },
      relevance: {
        title: 'Актуальность заявки',
        toPlaceholder: 'по: 22.03.2023',
        to: 'по'
      },
      paymentProcedure: {
        title: 'Порядок выплат %',
        list: {
          monthly: 'Ежемесячно',
          quarterly: 'Ежеквартально'
        }
      },
      provision: {
        title: 'Обеспечение',
        yes: 'Да',
        no: 'Нет',
        placeholder: 'Гарантия банка',
        list: {
          bank_guarantee: 'Гарантия банка'
        }
      }
    },
    profile: {
      rate: 'Рейтинг:',
      status: {
        active: 'Активный',
        new: 'Не активный',
        pending: 'В ожидании',
        declined: 'Не активный',
        blocked: 'Заблокирован'
      },
      role: {
        title: 'Статус: ',
        lender: 'Займодавец',
        borrower: 'Заёмщик',
        guarantor: 'Поручитель'
      },
      borrower: {
        request: {
          amount: {
            title: 'Сумма займа'
          },
          term: {
            title: 'Cрок'
          },
          percent: {
            title: '% годовых'
          },
          outcome: {
            title: 'К возврату'
          },
          create: {
            title: 'Создать заявку'
          }
        },
        activeRequests: {
          title: 'Мои заявки'
        },
        activeOffers: {
          title: 'Мои займы',
          items: {
            issued: 'Дата выдачи',
            borrower_rating: 'Рейтинг заемщика',
            amount: 'Сумма',
            term: 'Срок',
            percent: '% годовых',
            income: 'Итого к возврату',
            issuedBy: 'Дата возврата',
            peni: 'Пени',
            totalAmount: 'Итого к погашению',
            status: 'Статус заявки',
            last_claim_signed_at: 'Дата последней претензии',
            last_legal_action_signed_at: 'Дата последнего искового заявления'
          },
          status: {
            payment: 'Задолженность',
            closed: 'Оплачено',
            expired: 'Просрочено',
            paid: 'Активная',
            on_due: 'Задолженность',
            current_debt: 'Задолженность',
            repaid: 'Оплачено',
            payment_pending: 'В ожидании',
            need_sign: 'Ожидает подписи',
            [TradeStatus.TRANSACTION_PENDING]: 'Ожидание перевода',
            [TradeStatus.PROCESSED]: 'Погашено',
            [TradeStatus.TRANSACTION_FAILED]: 'Ошибка перевода',
            [TradeStatus.ACTIVE]: 'Задолженность',
            [TradeStatus.OVERDUE]: 'Просрочено',
            [TradeStatus.MAX_OVERDUE]: 'Просрочено'
          },
          error: {
            payment_is_unavailable: 'Вы сможете погасить займ {0}'
          },
          btn: 'Оплатить',
          transactions: 'Транзакции',
          payModal: {
            title: 'Сумма к оплате:',
            currency: 'BYN',
            payBtn: 'Оплатить'
          }
        },
        noCardLink: 'Добавьте',
        noCardText: ' карту, на которую вы хотите получить деньги',
        ratingInProgress: 'Ожидается выставление рейтинга',
        noRate: '(В данный момент ожидается выставление рейтинга)',
        bank_report: {
          start: 'Для выставления рейтинга (скоринговой оценки) необходимо ',
          link: 'дать согласие',
          end: ' на предоставление кредитного отчёта из Кредитного регистра'
        }
      },
      lender: {
        offer: {
          amount: {
            title: 'Сумма займа',
            placeholderStart: 'от',
            placeholderEnd: 'до'
          },
          term: {
            title: 'Срок',
            placeholderStart: 'от',
            placeholderEnd: 'до',
            days: 'дней',
            month: 'месяцев'
          },
          percent: {
            title: '% годовых',
            placeholderStart: 'от',
            placeholderEnd: 'до'
          },
          income: {
            title: 'Доход*'
          },
          rating: {
            title: 'Рейтинг',
            placeholder: 'от 0 до 100'
          },
          count: {
            title: 'Кол-во займов',
            placeholder: '1',
            notice:
              'Обратите внимание, что выбрав количество займов (публикаций) ваше предложение будет публиковаться исходя из заявленного вами числа раз. Соответственно такое количество займов может быть выдано вами (в т.ч. автоматически) на аналогичных условиях, если на него откликнутся Заёмщики.'
          },
          btn: 'Создать заявку',
          toastMsg: 'Заявка успешно создана',
          publish: 'Опубликовать',
          unpublish: 'Снять с публикации'
        },
        activeOffers: {
          title: 'Мои заявки',
          moreOffers: 'Развернуть ещё',
          items: {
            amount: 'Сумма',
            term: 'Срок',
            percent: '% годовых',
            income: 'Доход*',
            rating: 'Рейтинг',
            count: 'Кол-во займов',
            status: 'Публикация'
          },
          published: {
            yes: 'Да',
            no: 'Нет',
            published: 'Да',
            not_published: 'Нет',
            blocked: 'Заблокирована',
            active: 'Опубликована',
            draft: 'Не опубликована',
            processed: 'Обработана',
            canceled: 'Отменена'
          },
          publishBtn: {
            published: 'Снять с публикации',
            not_published: 'Опубликовать'
          }
        },
        expiredLoan: {
          link: 'Создать Претензию',
          text: 'Оформление искового заявления в третейский суд'
        },
        expiredModal: {
          title: 'Вы хотите воспользоваться функцией “Создание Претензии”',
          advicesBtn: 'Практические советы',
          claimBtn: 'Перейти к созданию Претензии'
        },
        noCardLink: 'Добавьте',
        noCardText: ' карту, на которую вы хотите получить деньги',
        noScoringAgreement: {
          start: 'Для выставления рейтинга (скоринговой оценки) необходимо ',
          link: 'дать согласие',
          end: ' на предоставление кредитного отчёта из Кредитного регистра'
        }
      },
      biometry: {
        steps: {
          verification_required: 'Необходимо пройти верификацию',
          verification_failed: 'Верификация не пройдена',
          verification_attempts: 'Верификация с помощью биометрии - {actual}/{max} попытки'
        },
        widgets: {
          beforeBlocked: {
            heading: 'Ваш аккаунт (личный кабинет) будет заблокирован:',
            title: 'Попытки биометрической аутентификации исчерпаны',
            link_1: 'п.п.9 п.10 Правил сервиса потребительского онлайн-заимствования «Капуста – онлайн-заимствования»',
            link_2: 'п.7.6. Договора возмездного оказания услуг',
            text: 'Вы не прошли верификацию с использованием биометрии в МСИ и все доступные попытки прохождения биометрической аутентификации и верификации Вами исчерпаны. На основании {0} и руководствуясь {1}, с Вами будет расторгнут данный договор в одностороннем порядке с момента погашения всех действующих сделок займов. Ваш профиль переведен в статус «НЕАКТИВНЫЙ» и заключение новых сделок займов для Вас недоступно.'
          }
        }
      }
    },
    footer: {
      links: {
        about: 'О нас',
        contacts: 'Контакты',
        news: 'Новости',
        docs: 'Документы',
        legal: 'Законодательство',
        justice: 'Третейский суд',
        questions: 'Частые вопросы'
      },
      social: 'Мы в соц. сети:',
      legal: {
        cookies:
          'Мы используем сооkies для сбора обезличенных персональных данных. Оставаясь на сайте, Вы соглашаетесь на сбор таких данных. Предоставление займов сопряжено с определенным риском.',
        company:
          'ООО «ИФС» (С) Свидетельство № 1 Национального банка Республики Беларусь от 14.01.2022'
      }
    },
    header: {
      signOutBtn: 'Выйти',
      signInBtn: 'Войти',
      getLoan: 'Получить займ',
      returnLoan: 'Вернуть займ',
      investing: 'Инвестировать',
      about: 'О нас',
      register: 'Зарегистрироваться'
    },
    mainPage: {
      heading: {
        title: 'Капуста — сервис онлайн-заимствования',
        desc: {
          link: 'Свидетельство ',
          textStart: '№ 1 Национального банка Республики Беларусь ',
          textEnd:
            'о включении в реестр операторов сервисов онлайн-заимствования',
          textNotice:
            '(Указ Президента Республики Беларусь от 25 мая 2021 г. № 196)'
        },
        btn: 'ЗАРЕГИСТРИРОВАТЬСЯ'
      },
      offers: {
        title: {
          lender: 'Они готовы выдать деньги прямо сейчас!',
          borrow: 'Они готовы взять деньги прямо сейчас!'
        },
        btn_take: 'Получить деньги',
        btn_get: 'Инвестировать',
        tableTitle: {
          amount: 'Сумма',
          return: 'К возвр.',
          returnMobile: 'К возврату',
          issuedBy: 'Срок',
          rating: 'Рейтинг'
        },
        more: 'Раскрыть ещё'
      },
      hiw: {
        title: 'Как работает Капуста:',
        first: {
          title: 'Регистрация',
          desc: 'немного терпения и вы становитесь полноценным участником Сервиса и одновременно владельцем личного «карманного» Банка. Регистрация  через МСИ и для желающих предоставлять займы — гораздо быстрее'
        },
        second: {
          title: 'Получение займа',
          desc: 'все сделки займов заключаются напрямую между физическими лицами. Для получения денег воспользуйтесь предложениями от Займодавцев и сделайте свой выбор или создайте свою заявку на займ'
        },
        third: {
          title: 'Выдача займа',
          desc: 'на нашем сайте вы можете создать свои уникальные предложения по займам. Займодавцы не ограничены в количестве совершаемых сделок на нашем Сервисе'
        }
      },
      advantages: {
        title: 'Почему все выбирают Капусту:',
        items: {
          first: 'БЕЗ: справок, залогов, страховок и поручителей',
          second: '95% вероятность одобрения. Деньги на карту за 15 минут',
          third: 'Выдача и погашение займа круглосуточно',
          fourth: 'SSL сертификат, защита личной информации',
          fifth: 'Справочно-информационная поддержка',
          sixth: 'Взыскание в третейском суде'
        }
      },
      targetLoans: {
        title:
          'Присмотритесь к целевым займам, разработанным специально для вас!',
        amount: 'Сумма:',
        time: 'Срок:',
        return: 'К возврату:',
        days: 'дней',
        btn: 'Получить деньги',
        first: 'Простой',
        second: 'До зарплаты',
        third: 'Универсальный',
        fourth: 'Крупная сделка',
        fifth: 'На ремонт',
        sixth: 'На отдых'
      },
      newLoan: {
        title: 'Не нашли подходящего предложения?',
        descFirstRow: 'Хотите получить займ на своих условиях?',
        descSecondRow: 'Создайте свою Уникальную заявку!'
      },
      partners: {
        title: 'Наши партнёры'
      }
    },
    notFound: {
      title: 'Упс!',
      notice: 'Возможно, запрашиваемая страница временно не доступна :(',
      btn: 'Вернуться на главную'
    },
    passwordRecovery: {
      backBtn: 'Вернуться',
      firstStep: {
        title:
          'Для того, чтобы восстановить доступ к личному кабинету, укажите свой логин',
        login: {
          label: 'Введите логин',
          btn: 'Далее'
        }
      },
      secondStep: {
        title:
          'СМС с кодом для восстановления пароля отправлено на Ваш телефон',
        otp: {
          label: 'Введите код',
          btn: 'Далее'
        }
      },
      thirdStep: {
        title: 'Создание нового пароля',
        password: {
          label: 'Новый пароль'
        },
        repeatPassword: {
          label: 'Новый пароль ещё раз'
        },
        saveBtn: 'Сохранить',
        cancelBtn: 'Отмена'
      }
    },
    docksUploader: {
      errorMsg: 'Необходимо загрузить файлы',
      validationErrorMsg: 'Не верный формат файла',
      downloadBtn: 'Прикрепить файл'
    },
    hello: 'works',
    errorModal: {
      title: 'Ошибка',
      btn: 'OK'
    },
    signUp: {
      signUpBtn: 'Зарегистрироваться',
      isDataFullAndCorrect: {
        text: 'Я подтверждаю полноту и корректность предоставленных мной данных'
      },
      isAgreeWithServiceRules: {
        text: 'Я ознакомлен(а) и согласен(а) с ',
        link: 'Правилами работы Сервиса ',
        secondText: 'и ',
        secondLink: 'Политикой в отношении обработки персональных данных'
      },
      isAgreeWithPersonalDataActions: {
        textFirst: 'Я даю ',
        link: 'согласие',
        textSecond: ' на обработку своих персональных данных'
      },
      isAgreeWithServiceAgreement: {
        textFirst: 'Я ознакомлен и согласен с условиями ',
        link: 'Договора возмездного оказания услуг',
        textSecond: ' и принимаю условия данной оферты'
      },
      isAgreeToGetBankReport: {
        textFirst: 'Даю своё ',
        link: 'согласие на получение отчёта',
        textSecond: ' из Кредитного регистра НБРБ'
      },
      files_requirements_real_estate: 'PDf, PNG, JPG, JPEG, JIP, TIFF, максимум 4 файла, максимальный размер файла 10 MB',
      files_requirements_car: 'PDF, PNG, JPG, JPEG, JIP, TIFF, максимум 4 файла, максимальный размер файла 10 MB: обязательно прикрепите 2 файла с изображением техпаспорта с лицевой и оборотной стороны',
      title: 'Анкета',
      description:
        'Будьте внимательны на данном этапе. * - обязательны для заполнения',
      basicBlock: {
        title: 'Контактные данные',
        keyWord: {
          title: 'Кодовое слово',
          noticeMsg: 'Может использоваться для подтверждения операций'
        },
        msisdn: {
          title: 'Номер телефона'
        },
        otp: {
          title: 'Код из SMS',
          sendOtp: 'Получить код'
        },
        email: {
          title: 'Email'
        },
        fullName: {
          title: 'Полное наименование*'
        },
        shortName: {
          title: 'Краткое наименование*'
        },
        unp: {
          title: 'УНП*'
        },
        formerName: {
          title: 'Регистрирующий орган*'
        },
        regNumber: {
          title: 'Регистрационный номер*'
        },
        regDate: {
          title: 'Дата регистрации*',
          placeholder: 'ДД/ММ/ГГГГ'
        },
        otpToastMsg: 'Код выслан'
      },
      role: {
        title: 'Выберите статус'
      },
      mainData: {
        title: 'Основные сведения',
        surname: {
          title: 'Фамилия'
        },
        name: {
          title: 'Имя'
        },
        middleName: {
          title: 'Отчество',
          noMiddleName: 'Нет отчества'
        },
        birthDate: {
          title: 'Дата рождения',
          placeholder: 'ДД.ММ.ГГГГ'
        },
        citizenship: {
          title: 'Гражданство'
        },
        gender: {
          title: 'Пол',
          male: 'Мужской',
          female: 'Женский'
        },
        birthPlace: {
          title: 'Место рождения',
          placeholder: 'В точном соответствии с паспортными данными'
        }
      },
      passwordBlock: {
        title: 'Логин и пароль',
        login: {
          title: 'Логин*'
        },
        password: {
          title: 'Пароль'
        }
      },
      addressBlock: {
        title: 'Адрес',
        registrationAdress: {
          title: 'Адрес регистрации (прописка)',
          theSame: 'Адрес проживания совпадает с адресом регистрации'
        },
        livingAddress: {
          title: 'Адрес проживания',
          placeholder:
            'Заполнить, если адрес проживания не совпадает с адресом регистрации'
        },
        country: {
          title: 'Страна',
          placeholder: 'Беларусь',
          countryList: {
            112: 'Беларусь',
            643: 'Россия',
            3: 'Казахстан'
          }
        },
        locality: {
          title: 'Населённый пункт*'
        },
        area: {
          title: 'Область*'
        },
        district: {
          title: 'Район*'
        },
        formerName: {
          title: 'Тип населённого пункта*',
          formerNameList: {
            1: 'Агрогородок',
            2: 'Город',
            3: 'Городской поселок',
            4: 'Деревня',
            5: 'Курортный поселок',
            6: 'Поселок',
            7: 'Рабочий поселок',
            8: 'Село',
            9: 'Хутор'
          }
        },
        postalCode: {
          title: 'Почтовый индекс*',
          placeholder: '220022'
        },
        street: {
          title: 'Улица*'
        },
        house: {
          title: 'Дом*'
        },
        building: {
          title: 'Корпус'
        },
        office: {
          title: 'Квартира'
        }
      },
      postalAddressBlock: {
        title: 'Почтовый адрес',
        addressTheSame: 'Почтовый адрес совпадает с адресом места нахождения',
        country: {
          title: 'Страна',
          placeholder: 'Беларусь',
          countryList: {
            112: 'Беларусь',
            643: 'Россия',
            3: 'Казахстан'
          }
        },
        locality: {
          title: 'Населённый пункт*'
        },
        area: {
          title: 'Область*'
        },
        district: {
          title: 'Район*'
        },
        formerName: {
          title: 'Тип населённого пункта*',
          formerNameList: {
            1: 'Агрогородок',
            2: 'Город',
            3: 'Городской поселок',
            4: 'Деревня',
            5: 'Курортный поселок',
            6: 'Поселок',
            7: 'Рабочий поселок',
            8: 'Село',
            9: 'Хутор'
          }
        },
        postalCode: {
          title: 'Почтовый индекс*',
          placeholder: '220022'
        },
        street: {
          title: 'Улица*'
        },
        house: {
          title: 'Дом*'
        },
        building: {
          title: 'Корпус'
        },
        office: {
          title: 'Квартира'
        }
      },
      idBlock: {
        title: 'Данные, удостоверяющие личность',
        idType: {
          title: 'Вид документа*',
          idTypeList: {
            1: 'Паспорт гражданина РБ',
            2: 'Паспорт иностранного гражданина',
            3: 'Вид на жительство',
            4: 'Удостоверение беженца',
            5: 'Идентификационная карта гражданина РБ',
            6: 'Биометрический вид на жительство в РБ'
          }
        },
        idNumber: {
          title: 'Серия и номер документа'
        },
        idIdentification: {
          title: 'Идентификационный номер (ID)'
        },
        mainDocument: {
          title: 'Основной документ',
          placeholder: '',
          list: {
            1: 'Паспорт гражданина РБ',
            2: 'Вид на жительство',
            3: 'Идентификационная карта гражданина РБ',
            4: 'Биометрический вид на жительство в РБ',
            5: 'Удостоверение беженца'
          }
        },
        series: {
          title: 'Серия',
          placeholder: 'AA'
        },
        number: {
          title: 'Номер',
          placeholder: '1234567'
        },
        idDateIssue: {
          title: 'Дата выдачи',
          placeholder: 'ДД.ММ.ГГГГ'
        },
        expireDate: {
          title: 'Срок действия документа',
          placeholder: 'ДД.ММ.ГГГГ'
        },
        idIssuedBy: {
          title: 'Кем выдан',
          placeholder: 'В точном соответствии с паспортными данными'
        }
      },
      livingAddressBlock: {
        title: 'Адрес места жительства',
        country: {
          title: 'Страна',
          placeholder: 'Беларусь',
          countryList: {
            112: 'Беларусь',
            643: 'Россия',
            3: 'Казахстан'
          }
        },
        locality: {
          title: 'Населённый пункт*'
        },
        area: {
          title: 'Область*'
        },
        district: {
          title: 'Район*'
        },
        formerName: {
          title: 'Тип населённого пункта*',
          formerNameList: {
            1: 'Агрогородок',
            2: 'Город',
            3: 'Городской поселок',
            4: 'Деревня',
            5: 'Курортный поселок',
            6: 'Поселок',
            7: 'Рабочий поселок',
            8: 'Село',
            9: 'Хутор'
          }
        },
        postalCode: {
          title: 'Почтовый индекс*',
          placeholder: '220022'
        },
        street: {
          title: 'Улица*'
        },
        house: {
          title: 'Дом*'
        },
        building: {
          title: 'Корпус'
        },
        office: {
          title: 'Квартира'
        }
      },
      destinationAddressBlock: {
        title: 'Адрес места пребывания',
        addressTheSame:
          'Место пребывания совпадает с местом жительства (регистрации)',
        country: {
          title: 'Страна',
          placeholder: 'Беларусь',
          countryList: {
            112: 'Беларусь',
            643: 'Россия',
            3: 'Казахстан'
          }
        },
        locality: {
          title: 'Населённый пункт*'
        },
        area: {
          title: 'Область*'
        },
        district: {
          title: 'Район*'
        },
        formerName: {
          title: 'Тип населённого пункта*',
          formerNameList: {
            1: 'Агрогородок',
            2: 'Город',
            3: 'Городской поселок',
            4: 'Деревня',
            5: 'Курортный поселок',
            6: 'Поселок',
            7: 'Рабочий поселок',
            8: 'Село',
            9: 'Хутор'
          }
        },
        postalCode: {
          title: 'Почтовый индекс*',
          placeholder: '220022'
        },
        street: {
          title: 'Улица*'
        },
        house: {
          title: 'Дом*'
        },
        building: {
          title: 'Корпус'
        },
        office: {
          title: 'Квартира'
        }
      },
      authorizedPersonBlock: {
        title: 'Данные уполномоченного лица',
        citizen: 'Резидент Республики Беларусь',
        notCitizen: 'Нерезидент Республики Беларусь',
        phone: {
          title: 'Номер телефона*',
          placeholder: '+ 375 (29) 111-11-11'
        },
        basedOn: {
          title: 'На основании чего действует руководитель*'
        },
        surname: {
          title: 'Фамилия'
        },
        name: {
          title: 'Имя'
        },
        middleName: {
          title: 'Отчество',
          noMiddleName: 'Нет отчества'
        },
        citizenship: {
          title: 'Гражданство*',
          countryList: {
            112: 'Беларусь',
            643: 'Россия',
            3: 'Казахстан'
          }
        },
        birthplace: {
          title: 'Место рождения*',
          placeholder: 'В точном соответствии с паспортными данными'
        },
        post: {
          title: 'Должность*'
        },
        website: {
          title: 'Адрес сайта в Интернете'
        },
        email: {
          title: 'Адрес электронной почты'
        }
      },
      jobBlock: {
        title: 'Сведения о работе',
        typeOfEmployment: {
          title: 'Вид занятости',
          list: {
            officially: 'Работаю официально',
            tmp_unemployed: 'Временно не работаю',
            maternity_leave: 'В декретном отпуске',
            entrepreneur: 'Предприниматель',
            other_source: 'Иной источник дохода',
            pensioner: 'Пенсионер',
            working_pensioner: 'Работающий пенсионер',
            self_employed: 'Самозанятый',
            student: 'Студент'
          }
        },
        fieldOfEmployment: {
          title: 'Сфера занятости',
          list: {
            null: '',
            not_indicated: '-',
            finance: 'Банковское дело / финансы',
            military: 'Вооружённые силы / правоохр. органы',
            government: 'Государственное управление',
            healthcare: 'Здравоохранение / фармация',
            it: 'IT',
            public_utilities: 'Коммунальное хозяйство',
            culture: 'Культура',
            education: 'Наука и образование',
            industry: 'Промышленность',
            entertainment: 'Развлечения',
            agriculture: 'Сельское хозяйство',
            construction: 'Строительсво',
            trading: 'Торговля',
            services: 'Услуги'
          }
        },
        nameOfOrganization: {
          title: 'Наименование организации'
        },
        post: {
          title: 'Должность',
          list: {
            null: '',
            not_indicated: '-',
            specialist: 'Специалист',
            worker: 'Рабочий',
            middle_manager: 'Руководитель среднего звена',
            chief_accountant: 'Главный бухгалтер',
            chief_engineer: 'Главный инженер',
            deputy_head: 'Заместитель руководителя',
            head: 'Руководитель',
            other: 'Другое'
          }
        },
        otherPositionType: {
          title: 'Введите должность*'
        },
        phoneNumber: {
          title: 'Телефон организации',
          placeholder: '+ 375 (29) 111-11-11'
        },
        workExperience: {
          title: 'Стаж работы в данной организации (мес.)',
          placeholder: 'Мес.'
        }
      },
      entityDocsBlock: {
        title: 'Документы юридического лица',
        description:
          'Формат загружаемого файла: jpeg, jpg, png, pdf. Максимальный размер 1-го загружаемого файла 5 МБ',
        order: {
          title:
            '1. Документ, удостоверяющий право уполномоченного лица (приказ, распоряжение)',
          error: 'Необходимо загрузить файлы'
        },
        register: {
          title: '2. Свидетельство о регистрации'
        },
        charter: {
          title: '3. Устав'
        },
        extra: {
          title: '4. Дополнительные файлы'
        }
      },
      roleBlock: {
        borrower: 'Заёмщик',
        lender: 'Займодавец',
        guarantor: 'Поручитель'
      },
      extraBlock: {
        title: 'Дополнительные сведения',
        education: {
          title: 'Образование',
          list: {
            incomplete_secondary: 'Незаконченное среднее',
            secondary: 'Среднее',
            specialized_secondary: 'Средне-специальное',
            incomplete_higher: 'Незаконченное высшее',
            higher: 'Высшее'
          }
        },
        driversLicense: {
          title: 'Водительское удостоверение',
          yes: 'Имею',
          no: 'Не имею'
        },
        car: {
          title: 'Автомобиль в собственности',
          fileMessage: 'Технический паспорт автомобиля',
          yes: 'Имею',
          no: 'Не имею'
        },
        realEstate: {
          title: 'Недвижимость в собственности',
          fileMessage: 'Свидетельство о регистрации недвижимого имущества',
          yes: 'Имею',
          no: 'Не имею'
        },
        familyStatus: {
          title: 'Семейное положение',
          list: {
            single: 'Холост / Не замужем',
            married: 'Женат / Замужем'
          }
        },
        dependensNumber: {
          title: 'Количество иждивенцев'
        },
        childExpenses: {
          title:
            'Обязанность возмещать расходы, затраченные государством на содержание детей (в детских домах, интернатах, спец.учреждениях и пр.)',
          yes: 'Имею',
          no: 'Не имею'
        },
        totalIncome: {
          title: 'Общий ежемесячный доход в BYN'
        }
      }
    },
    signIn: {
      title: 'Добро пожаловать в Капусту!',
      loginPlaceholder: 'Номер телефона',
      passwordPlaceholder: 'Пароль',
      otpPlaceholder: 'Код из SMS',
      sendCode: 'ОТПРАВИТЬ КОД',
      signInBtn: 'Войти',
      msi: 'Зарегистрироваться',
      forgotPassword: 'Забыли пароль?',
      otpToastMsg: 'Код выслан',
      blockModal: {
        declined: {
          title: 'Попытки биометрической аутентификации исчерпаны',
          link_1: 'п.п.9 п.10 Правил сервиса потребительского онлайн-заимствования «Капуста – онлайн-заимствования»',
          link_2: 'п.7.6. Договора возмездного оказания услуг',
          main: 'с Вами будет расторгнут данный договор в одностороннем порядке с момента погашения всех действующих сделок займов.',
          text: 'Вы не прошли верификацию с использованием биометрии в МСИ и все доступные попытки прохождения биометрической аутентификации и верификации Вами исчерпаны. На основании {0} \nи руководствуясь {1}, {2} Ваш профиль переведен в статус «НЕАКТИВНЫЙ» и заключение новых сделок займов для Вас недоступно.'
        },
        blocked: {
          title: 'Попытки биометрической аутентификации исчерпаны',
          link_1: 'На основании п.п.9 п.10 Правил сервиса потребительского онлайн-заимствования «Капуста – онлайн-заимствования»',
          link_2: 'п.7.6. Договора возмездного оказания услуг',
          main: 'с Вами расторгается данный договор в одностороннем порядке.',
          text: 'Вы не прошли верификацию с использованием биометрии в МСИ и все доступные попытки прохождения биометрической аутентификации и верификации Вами исчерпаны. {0} \nи руководствуясь {1}, {2} Ваш аккаунт (личный кабинет) заблокирован.'
        }
      }
    },
    cardsPage: {
      main_card_title: 'Основная карта',
      other_cards_title: 'Прочие карты',
      amountNotice:
        'При добавлении карты минимальный остаток должен составлять 1 бел.рубль',
      add_card: 'Добавить карту',
      empty: 'Список карт пуст',
      notVerified: 'Карта не верифицирована',
      min_amount_notion: 'При добавлении карты минимальный остаток должен составлять 1 BYN ( Белорусский рубль )',
      card: {
        card: 'Номер карты',
        expiry: 'Срок действия',
        card_default: 'Основная карта',
        MIR: 'Мир/ Мгновенное зачисление возможно',
        VISA: 'Visa/ Мгновенное зачисление возможно',
        BELKART: 'BELKART/ Мгновенное зачисление возможно',
        MASTERCARD: 'Mastercard/ Мгновенное зачисление возможно',
        no_money: 'Недостаточно средств на счете для совершения операций. Пополните баланс банковской карты и подтвердите наличие средств на счете или выберите другую банковскую карту основным источником денежных средств',
        cardholder_verification_failed: 'Банковская карта не может быть основной, так как фамилия и имя в реквизитах банковской карты не совпадают с данными в профиле, необходимо добавить банковскую карту, где вы являетесь держателем карты',
        cardholder_verification_pending: 'На проверке'
      },
      actions: {
        verify_money: 'Подтвердить наличие средств',
        set_default: 'Сделать основной',
        delete: 'Удалить карту',
        add_card: 'Добавить карту',
        confirm: 'Подтвердить'
      },
      notice: {
        title: {
          set_default: 'Смена основной карты',
          delete: 'Вы действительно хотите удалить карту?',
          verify_balance: 'Подтверждение наличия средств'
        },
        text: {
          set_default: 'Вы уверены, что хотите выбрать банковскую карту с номером {cardNumber} в качестве основной карты ?',
          delete: 'При подтверждении удаления, информация о карте будет безвозвратно удалена. Вы уверены, что хотите удалить карту?',
          verify_balance: 'Для того, чтобы опубликовать заявку на главном экране и  предоставить займ, пожалуйста, подтвердите наличие средств на банковской карте'
        }
      },
      success_set_default: 'Карта {cardNumber} выбрана \nосновной картой!',
      failed_set_default: 'Изменения в Мои карты \nне сохранены!'
    },
    msi: {
      regBtn: 'Продолжить',
      cancelBtn: 'Отмена',
      note: 'Регистрация на нашем сервисе реализована через Межбанковскую Систему Индентификации (МСИ)',
      msi_confirmation: {
        title: 'Подтвердите прохождение биометрической аутентификации',
        text: 'Вы подтверждаете, что не находитесь под влиянием 3-х лиц в момент прохождения биометрической аутентификации?'
      }
    },
    sideMenu: {
      profile: 'Профиль',
      main: 'Главная',
      cards: 'Мои карты',
      settings: 'Настройки',
      archive: 'Архив',
      notifications: 'Уведомления',
      messages: 'Отправка сообщения администратору',
      'change-password': 'Смена пароля',
      'change-profile': 'Редактирование профиля',
      'change-contact-info': 'Редактирование контактной информации',
      'consent-management': 'Персональные данные',
      'create-claim': 'Создание претензии',
      loan: 'Транзакции',
      documents: 'Документы',
      'sign-claim': 'Подписать претензию',
      'sign-legal-action': 'Подписать исковое заявление'
    },
    loans: {
      status: {
        null: '',
        active: 'Задолженность',
        overdue: 'Просрочено',
        max_overdue: 'Просрочено',
        need_sign: 'Ожидает подписания'
      }
    },
    offers: {
      status: {
        null: '',
        active: 'Опубликована',
        draft: 'Не опубликована'
      }
    }
  },
  Apply: 'Применить',
  Clear: 'Очистить',
  'Lend amount': 'Сумма займа',
  'Lend period': 'Срок займа',
  Rating: 'Рейтинг',
  from: 'от',
  to: 'до',
  days: 'дней',
  'Rate tooltip': 'Вам доступен для выбора любой займ с вашим рейтингом и ниже',
  'Empty filters': 'Нет заявок в соответствии выбранным критериям',
  'Borrower full name': 'ФИО заемщика',
  'Lender full name': 'ФИО займодавца',
  'Agreement #': 'Договор №',
  active: 'Активный',
  Change: 'Изменить'
}

export default ru
