<template>
  <div class="documents-page-wrapper">
    <static-pages-header @mobileMenuToggle="mobileMenuToggle" />
    <div class="documents-container" :class="{'heading-container-mobile-menu-open': isMobileMenuOpen}">
      <div class="documents-title">{{ $t('message.documentsPage.title') }}</div>

      <a href="/static/rules_09_08_2024.pdf" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.rules') }}</div>
      </a>

      <a href="/static/serviceContract_09_08_2024.pdf" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.serviceContract') }}</div>
      </a>
      <a href="/static/contractTemplate.pdf" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.contractTemplate') }}</div>
      </a>

      <a href="/static/scoring.pdf" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.scoring') }}</div>
      </a>

      <a href="/static/paritetOffer.docx" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.offer') }}</div>
      </a>

      <a href="/static/personalDataProcessing.pdf" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.personalDataProcessing') }}</div>
      </a>

      <div class="font-semibold text-lg my-5">Архив (ред. до 27.08.2024)</div>

      <a href="/static/paritetOffer_27_08_2024.pdf" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.offer') }}</div>
      </a>

      <div class="font-semibold text-lg my-5">Архив (ред. до 08.08.2024)</div>

      <a href="/static/rules_24.05.2024.pdf" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.rules') }}</div>
      </a>

      <a href="/static/serviceContract_24.05.2024.pdf" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.serviceContract') }}</div>
      </a>

      <div class="font-semibold text-lg my-5">Архив (ред. до 23.05.2024)</div>

      <a href="/static/rules.pdf" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.rules') }}</div>
      </a>

      <a href="/static/serviceContract.pdf" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.serviceContract') }}</div>
      </a>

      <div class="font-semibold text-lg my-5">Архив (ред. до 10.04.2024)</div>

      <a href="/static/paritetOffer_10.04.2024.pdf" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.offer') }}</div>
      </a>

      <div class="font-semibold text-lg my-5">Архив (ред. до 07.04.2024)</div>

      <a href="/static/rules_07_04.pdf" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.rules') }}</div>
      </a>

      <a href="/static/serviceContract_07_04.pdf" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.serviceContract') }}</div>
      </a>

      <div class="font-semibold text-lg my-5">Архив (ред. до 03.04.2024)</div>
      <a href="/static/paritetOffer_old.pdf" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.offer') }}</div>
      </a>

      <div class="font-semibold text-lg my-5">Архив (ред. до 06.03.2024)</div>
      <a href="/static/rules_old.pdf" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.rules') }}</div>
      </a>

      <a href="/static/serviceContract_old.pdf" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.serviceContract') }}</div>
      </a>
      <div class="font-semibold text-lg my-5">Архив (ред. до 26.02.2024)</div>
      <a href="/static/rules_older.pdf" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.rules') }}</div>
      </a>

      <a href="/static/serviceContract_older.pdf" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.serviceContract') }}</div>
      </a>
      <a href="/static/contractTemplate_old.pdf" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.contractTemplate') }}</div>
      </a>
      <a href="/static/personalDataProcessing_old.pdf" target="_blank" class="info-container-item-document-block mb20">
        <img src="../../../public/custom/images/document-img.svg" alt="Document image">
        <div>{{ $t('message.documentsPage.documents.personalDataProcessing') }}</div>
      </a>
    </div>
  </div>
</template>

<script>
import StaticPagesHeader from '../../components/common/StaticPagesHeader.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Documents',
  components: {
    StaticPagesHeader
  },
  data() {
    return {
      isMobileMenuOpen: false
    }
  },
  methods: {
    mobileMenuToggle(value) {
      this.isMobileMenuOpen = value
    },
    signUp() {
      this.$router.push({ name: 'msi' })
    }
  },
  computed: {
    ...mapGetters(['profile']),
    isAuthorized() {
      return this.profile?.role
    }
  }
}
</script>

<style lang="scss" scoped>
.documents-page-wrapper {
  @include base-page-wrapper;
  @include static-heading-container;
}

.documents-container {
  @include base-page-container;

  padding: 70px 50px 140px 50px;
  box-sizing: border-box;
  justify-content: flex-start;
  flex-direction: column;
}

.documents-title {
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 40px;
  line-height: 125%;
  color: #181A1B;
}

.info-container-item-document-block {
  margin-top: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;

  img {
    height: 34px;
    width: 34px;
    margin-right: 5px;
  }

  div {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #41BF7B;
    text-decoration: none;
  }
}

@media screen and (max-width: 940px) {
  .documents-container {
    padding: 50px 0 140px 0;
  }

  .documents-title {
    font-size: 30px;
  }
}
</style>
