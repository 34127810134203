import axios from './axios'
import { StorageKey, UserProfile } from '@/models'
import { environment } from '@/environments/env'

export default class AuthService {
  static signIn(payload): Promise<{ access: string, refresh: string }> {
    return axios.post('/user/sign-in/', payload)
  }

  static getOtpCode(phone_number) {
    return axios.post('/notification/sms/otp/', { phone_number })
  }

  static getSignInOtp(phone, password) {
    return axios.post('/user/sign-in/otp/', { phone, password })
  }

  static getAuthorizedOtpCode(phone_number: string) {
    return axios.post('/notification/sms/otp/', { phone_number })
  }

  static getProfile(): Promise<UserProfile> {
    return axios.get('/user/profile/')
  }

  static refreshToken() {
    const refresh = localStorage.getItem(StorageKey.REFRESH)

    return fetch(environment.api + environment.apiVersion + '/user/refresh-token/', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ refresh })
    })
      .then(res => res.json())
      .then(({ access }) => access)
  }
}
